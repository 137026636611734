import React from 'react';
import './CardsInfo.css';

function CardsInfo(props) {

    const {
        cards,
        corner
    } = props;

    return (
        <div className="general-cards-section">

            <div style={{width: '80%'}}>
                <div className="number-container">
                    <span className="home-number">{cards.value.home}</span>
                    <span className="percent-font-size" style={{fontWeight: 'normal'}}>SARI KART</span>
                    <span className="away-number">{cards.value.away}</span>
                </div>

                <div className="home-percent">
                    <div className="progress" style={{flex: cards?.value?.home}}>

                    </div>
                    <div className="away-percent" style={{flex: cards?.value?.away}}>
                    </div>
                </div>
            </div>

            <div style={{width: '80%'}}>
                <div className="number-container">
                    <span className="home-number">{corner.value.home}</span>
                    <span className="percent-font-size" style={{fontWeight: 'normal'}}>KORNER</span>
                    <span className="away-number">{corner.value.away}</span>
                </div>

                <div className="home-percent">
                    <div className="progress" style={{flex: corner?.value?.home}}></div>
                    <div className="away-percent" style={{flex: corner?.value?.away}}></div>
                </div>
            </div>
        </div>
    );
}

export default CardsInfo;
