import React, {useContext} from 'react';
import padLock from '../../../assets/icons/padlock.png'
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../context/AppContext";

function MatchDetailsStatsEvent(props) {
    const {isPro} = useContext(AppContext);

    const {matchDetailTeamData, i18String} = props;

    const navigate = useNavigate();
    const toJoinPremium = () => {
        navigate('/join-premium')
    }

    const getBackgroundColor = (value) => {
        // Yüzde işaretini kaldır ve sayıya dönüştür
        const number = parseFloat(value.replace('%', ''));

        if (number > 90) {
            return 'var(--H3Green90)'; // 90%'dan büyükse yeşil
        } else if (number > 60) {
            return 'var(--H3Green60)'; // 60%'dan büyükse sarı
        } else {
            return 'white'; // Diğer durumlarda beyaz
        }
    };

    return (
        <div className={'stats-section'}>
            {
                matchDetailTeamData && matchDetailTeamData.map(match => {
                    return (
                        <div className='match-detail-stats-title-section' key={match.name}>
                            <div className='match-detail-stats-title-container'>
                                <span className='match-detail-stats-title'>{i18String(match.name)}</span>
                            </div>
                            <div className={'stats-general-container'}>
                                {
                                    match.stats.map(stats => {
                                        return (
                                            <div className={'match-detail-stats-container'}>
                                                {
                                                    isPro ?
                                                        <div style={{ backgroundColor: getBackgroundColor(stats.hhome) }} className={'match-detail-stats'}>{stats.hhome}</div>
                                                        :
                                                        <img onClick={() => toJoinPremium()}  className='pad-lock-icon' src={padLock} alt=""/>
                                                }

                                                <div className={'match-detail-stats-name'}>{i18String(stats.name)}</div>

                                                {
                                                    isPro ?
                                                        <div style={{ backgroundColor: getBackgroundColor(stats.aaway) }} className={'match-detail-stats'}>{stats.aaway}</div>
                                                        :
                                                        <img onClick={() => toJoinPremium()} className='pad-lock-icon' src={padLock} alt=""/>
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default MatchDetailsStatsEvent;
