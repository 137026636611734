import React from 'react';
import shotOnTarget from '../../../../assets/lmt/shotOnTarget.svg'
import './ShotOnTarget.css';

function ShotOnTarget(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className='position-container'>
            <div className="shot-on-target-section">
                {
                    selectedEvent?.team === 'away' &&
                    <div className="away-icon">
                        <img className="match-situation-icon img-reverse" src={shotOnTarget} alt=""/>
                    </div>
                }

                <div className="shot-on-target-container">
                    <div className="shot-on-target">
                        <div className="shoton-target-info">
                            <div className="shot-on-target-match-time">
                                {selectedEvent.time}'
                            </div>
                            <div className="font-size">
                                <span style={{fontWeight: 500}}>{i18n?.shotOnTarget}</span> <br/> <span
                                className="shot-on-target-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    selectedEvent?.team === 'home' &&
                    <div className="home-icon">
                        <img className="match-situation-icon" src={shotOnTarget} alt=""/>
                    </div>
                }
            </div>
        </div>
    );
}

export default ShotOnTarget;
