import React, {useContext} from "react";
import {AppContext} from "../../context/AppContext";
import dayjs from "dayjs";
import yCard from "../../assets/icons/mackolik-ycard.gif";
import rCard from "../../assets/icons/mackolik-rcard.gif";

function RefereeSeasonMatches(props) {
    const {timeZone} = useContext(AppContext);

    const {refereeAllMatchesData, i18String} = props;

    const matchDateTime = (value) => {
        return dayjs(value).tz(timeZone).format("DD/MM/YY")
    }

    return (
        <div>
            {/*MATCHES HEADER*/}
            <div className='referee-matches-row-container'>
                <div></div>
                <div></div>
                <div className='referee-match-header-cell'>{i18String('Y.C.')}</div>
                <div className='referee-match-header-cell'>{i18String('R.C.')}</div>
                <div className='referee-match-header-cell'>{i18String('Foul')}</div>
                <div className='referee-match-header-cell'>{i18String('Pen')}</div>
                <div className='referee-match-header-cell'>{i18String('FT')}</div>
            </div>
            {
                refereeAllMatchesData && refereeAllMatchesData.map(match => {
                    return (
                        <div key={match.id}>
                            {/*MATCHES CONTENT MOBILE*/}
                            <div className='referee-matches-row-container-mobile'>
                                <div>
                                    <span className='referee-match-date'>{matchDateTime(match.dateTimeUtc)}</span>
                                </div>

                                <div className='referee-match-teams-and-score-section'>
                                    <div className='referee-match-teams-and-score-container referee-match-home-team-container'>
                                        <a target={"_blank"} href={`/team-detail/${match.homeTeamId}`}
                                           className={'referee-match-team-names'}>{match.homeTeam}</a>
                                    </div>

                                    <div className='referee-match-score-container'>
                                        <span className='referee-match-team-names referee-match-score'>{match.hscore} - {match.ascore}</span>
                                    </div>

                                    <div className='referee-match-teams-and-score-container'>
                                        <a target={"_blank"} href={`/team-detail/${match.awayTeamId}`}
                                           className={'referee-match-team-names'}>{match.awayTeam}</a>
                                    </div>
                                </div>

                                <div className='referee-match-teams-mobile'>
                                    <span className='referee-match-team-names'>{match.homeTeam}</span>
                                    <span className='referee-match-team-names'>{match.awayTeam}</span>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hyellow + match.ayellow > 0 &&
                                        <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={yCard} alt="Yellow Card"/>
                                    }
                                    <div className='referee-match-cell'>
                                        <span>{match.hyellow}</span>
                                        <span>{match.ayellow}</span>
                                    </div>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hred + match.ared > 0 &&
                                        <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={rCard} alt="Red Card"/>
                                    }
                                    <div className='referee-match-cell'>
                                        <span>{match.hred}</span>
                                        <span>{match.ared}</span>
                                    </div>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hfoul}</span>
                                    <span>{match.afoul}</span>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hpen}</span>
                                    <span>{match.apen}</span>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hscore}</span>
                                    <span>{match.ascore}</span>
                                </div>
                            </div>

                            {/*WEB*/}
                            <div className='referee-matches-row-container-web'>
                                <div>
                                    <span className='referee-match-date'>{matchDateTime(match.dateTimeUtc)}</span>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hyellow > 0 &&
                                        <div className='referee-match-cell'>
                                            <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={yCard} alt="Yellow Card"/>
                                            <span>{match.hyellow}</span>
                                        </div>
                                    }

                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hred > 0 &&
                                        <div className='referee-match-cell'>
                                            <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={rCard} alt="Red Card"/>
                                            <span>{match.hred}</span>
                                        </div>
                                    }
                                </div>

                                <div className='referee-match-cell'>
                                    {
                                        match.hfoul > 0 &&
                                        <span>F {match.hfoul}</span>
                                    }
                                </div>

                                <div className='referee-match-cell'>
                                    {
                                        match.hpen > 0 &&
                                        <span>P {match.hpen}</span>
                                    }
                                </div>

                                <div className='referee-match-teams-and-score-container referee-match-home-team-container'>
                                    <a target={"_blank"} href={`/team-detail/${match.homeTeamId}`}
                                       className={'referee-match-team-names'}>{match.homeTeam}</a>
                                </div>

                                <div className='referee-match-score-container'>
                                    <span className='referee-match-team-names referee-match-score'>{match.hscore} - {match.ascore}</span>
                                </div>

                                <div className='referee-match-teams-and-score-container'>
                                    <a target={"_blank"} href={`/team-detail/${match.awayTeamId}`}
                                       className={'referee-match-team-names'}>{match.awayTeam}</a>
                                </div>

                                <div className='referee-match-teams-mobile'>
                                    <span className='referee-match-team-names'>{match.homeTeam}</span>
                                    <span className='referee-match-team-names'>{match.awayTeam}</span>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.ayellow > 0 &&
                                        <div className='referee-match-cell'>
                                            <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={yCard} alt="Yellow Card"/>
                                            <span>{match.ayellow}</span>
                                        </div>
                                    }
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.ared > 0 &&
                                        <div className='referee-match-cell'>
                                            <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={rCard} alt="Red Card"/>
                                            <span>{match.ared}</span>
                                        </div>
                                    }
                                </div>

                                <div className='referee-match-cell'>
                                    {
                                        match.afoul > 0 &&
                                        <span>F {match.afoul}</span>
                                    }
                                </div>

                                <div className='referee-match-cell'>
                                    {
                                        match.apen > 0 &&
                                        <span>P {match.apen}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default RefereeSeasonMatches;
