import React from 'react';
import './CornerAnimation.css'

function CornerAnimation(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className="sr-lmt-state blink">
            <div className="sr-lmt-event-corner__wrapper">
                <div className="sr-lmt-corner-bottom__container srm-is-left">
                    <div className="sr-lmt-corner-bottom__overlay" style={{opacity: 1}}></div>
                    <div className="sr-lmt-corner-bottom__grass-container" style={{opacity: 0.08}}>
                        <svg preserveAspectRatio="xMidYMin slice" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 288 178"
                             className="sr-lmt-corner-bottom__grass" style={{fill: 'rgb(255, 255, 255)'}}>
                            <path
                                d="M288 99V68.4L0 176.8v1.2h104.6M288 45V26L0 112.6V141M176.2 0H129L0 25.3V37M81.3 0H34L0 6v9M271 0h-47.3L0 50.6v15.8m288 73.4L212.4 178H288"></path>
                        </svg>
                    </div>
                    <div className="sr-lmt-corner-bottom__overlay-shadow"></div>
                    <div className="sr-lmt-corner-bottom__banner-container" style={{opacity: 1}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 178"
                             className="sr-lmt-corner-bottom__banner-svg"
                             fill="#FFFFFF">
                            <path d="M288 17.5L0 98.7V74.2l288-73"></path>
                        </svg>
                        <div className="sr-lmt-corner-bottom__banners-wrapper-left">
                            <div
                                className="sr-lmt-corner-bottom__banners sr-lmt-corner-bottom__banners-left srm-is-left"
                                style={{backgroundImage: 'url(https://www.futbolverileri.com/img/img-label-fv@2x.6854ef3f.png)'}}></div>
                        </div>
                    </div>
                    <svg className="sr-lmt-corner-bottom__animation" viewBox="0 0 288 178">
                        <path strokeDashoffset="0" d="M260.6 28.1l27.7-8" className="sr-lmt-corner-bottom__animation-8"
                              style={{stroke: 'rgb(255, 255, 255)'}}></path>
                        <g opacity="0.1">
                            <path d="M270.7 64.6L260.6 60V28l10.1 4.3z" className="sr-lmt-corner-bottom__animation-5"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                            <path d="M288 50.6L260.6 60V27.9l27.4-7.7z" className="sr-lmt-corner-bottom__animation-5"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                            <path d="M288 27.2l-17.3 5.1-10.1-4.2 27.4-7.9z"
                                  className="sr-lmt-corner-bottom__animation-5"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                        </g>
                        <g className="sr-lmt-corner-bottom__opacity">
                            <path strokeDashoffset="0" d="M43.6 178.6l-16.4-26.9 261.4-93.5"
                                  className="sr-lmt-corner-bottom__animation-0"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                            <path strokeDashoffset="0" d="M241.4 75.1l38.1 20.7 9.1-3.9"
                                  className="sr-lmt-corner-bottom__animation-1"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                            <path strokeDashoffset="0" d="M161.9 103.5l86.8 66.8 39.9-20.8"
                                  className="sr-lmt-corner-bottom__animation-2"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                            <path strokeDashoffset="0" d="M27.0 140.7l-9.3-3.4 9.3-4.2v18.5"
                                  className="sr-lmt-corner-bottom__animation-3"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                            <path strokeDashoffset="0" d="M30.7 157.5h.6c3-.1 5.4-2.2 5.3-4.7 0-1.5-1-2.8-2.4-3.6"
                                  className="sr-lmt-corner-bottom__animation-4"
                                  style={{stroke: 'rgb(255, 255, 255)'}}></path>
                        </g>
                        <path strokeDashoffset="0" className="sr-lmt-corner-bottom__animation-6"
                              d="M270.7 32.3l-10.1-4.2V60l10.1 4.6"
                              style={{stroke: 'rgb(255, 255, 255)'}}></path>
                        <path strokeDashoffset="0" className="sr-lmt-corner-bottom__animation-7"
                              d="M270.7 64.8V32.3l17.9-5.2"
                              style={{stroke: 'rgb(255, 255, 255)'}}></path>
                    </svg>
                </div>

                <div className="sr-lmt-event-corner__ball-path srm-is-left">
                    <div className="sr-lmt-ballpath__wrapper">
                        <svg className="sr-lmt-svg" viewBox="0 0 568 352">
                            <path className="sr-lmt-ballpath__ball-shade" opacity="0.2222222222222222"
                                  d="M 49, 200 m -5, 0a 5, 2.5 0 1,0 10 , 0a 5 , 2.5 0 1,0 -10 , 0"></path>
                            <line className="sr-lmt-ballpath__path-shade" x1="500" y1="307" x2="51" y2="201"
                                  strokeDashoffset="2.4556642641655344"
                                  strokeDasharray="466.244571013969,468.244571013969" mask=""></line>
                            <path className="sr-lmt-ballpath__path" d="M 500 302 Q 270 15 50 180"
                                  strokeDashoffset="2.78133046037226"
                                  strokeDasharray="0,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,0.2097852172585135,0,544.2097852172585"
                                  fill="none" mask="" style={{stroke: 'rgb(255, 255, 255)'}}></path>
                            <circle className="sr-lmt-ballpath__ball" opacity="1" cx="51.42772674560547"
                                    cy="178.9344482421875"
                                    r="5"
                                    style={{fill: 'rgb(255, 255, 255)'}}></circle>
                        </svg>
                    </div>
                </div>

                <div className="corner-container blink">
                    <div className="corner">
                        <div className="corner-info">
                            <div className="corner-match-time">
                                {selectedEvent.time}'
                            </div>
                            <div className="font-size">
                                <span style={{fontWeight: 500}}>{i18n?.corner}</span> <br/> <span
                                className="corner-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CornerAnimation;
