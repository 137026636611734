import React from 'react';
import './GoalKick.css';

function GoalKick(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className='position-container'>
            <div className="goal-kick-container blink">
                <div className="goal-kick">
                    <div className="match-time">
                        {selectedEvent.time}'
                    </div>
                    <div className="font-size">
                        <div>
                            <span style={{fontWeight: 500}}>{i18n?.goalKick}</span> <br/> <span
                            className="goal-kick-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GoalKick;
