import React, {createContext, useEffect, useState} from 'react';
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import {fetchGEO} from "../api";
import enJSON from '../../src/components/languages/en.json';

import('dayjs/locale/tr');

export const AppContext = createContext();

const checkProUser = async () => {
    const userDetailResponse = localStorage.getItem('user');
    const userDetailObject = JSON.parse(userDetailResponse);
    if (userDetailObject !== null) {
        return !!userDetailObject?.premium?.premium;
    }
}

/*FOR CLIENT TYPE*/
const userAgent = navigator.userAgent;
const isMobile = /Mobile/i.test(userAgent);
const clientType = isMobile ? 'WEB_MOBILE' : 'WEB';

dayjs.extend(utc);
dayjs.extend(timezone);

export const AppProvider = ({children}, props) => {
    const [favMatches, setFavMatches] = useState([]);
    const [favBetModeMatches, setBetModeMatches] = useState([]);
    const [favLiveScoresMatches, setFavLiveScoresMatches] = useState([]);
    const [loginError, setLoginError] = useState(null);
    const [lang, setLang] = useState(null);
    const [localLang, setLocalLang] = useState(null);
    const [i18StringsFile, setI18StringsFile] = useState(enJSON);
    const [timeZone, setTimeZone] = useState(localStorage.getItem('time-zone') || dayjs.tz.guess());
    const [isPro, setIsPro] = useState(false);
    const [siteTheme, setSiteTheme] = useState(localStorage.getItem('data-theme') || 'light');
    const [geo, setGeo] = useState(null);

    useEffect(() => {
        checkProUser().then(isUserPro => {
            setIsPro(isUserPro);
        });
    }, [isPro]);

    /*GEO*/
    useEffect(() => {
        fetchGEO().then(geoData => {
            setGeo(geoData.data.data);
        });
    }, []);

    useEffect(() => {
        if (geo) {
            const language = localStorage.getItem('language') || (geo.countryCode === 'TR' ? 'tr' : 'en');
            const localLanguage = localStorage.getItem('local-lang') || (geo.countryCode === 'TR' ? 'tr' : 'en');

            if (language !== null && localLanguage !== null) {
                localStorage.setItem('language', language);
                localStorage.setItem('local-lang', localLanguage);
                setLang(language);
                setLocalLang(localLanguage);
            } else {
                localStorage.setItem('language', 'en');
                localStorage.setItem('local-lang', 'en');
                setLang('en');
                setLocalLang('en');
            }
        }
    }, [geo]);

    useEffect(() => {
        if (lang === 'tr') {
            import(`../../src/components/languages/tr.json`).then(language => {
                console.log('langgggg', language);
                setI18StringsFile(language);
            })
        } else {
            import(`../../src/components/languages/en.json`).then(language => {
                console.log('langgggg', language);
                setI18StringsFile(language);
            })
        }
    }, [lang]);

    const i18String = (value, param) => {
        if (param) {
            const { statsName, typeName } = param;
            let translation = i18StringsFile[value];

            if (translation) {
                translation = translation.replace("{statsName}", i18StringsFile[statsName]);
                translation = translation.replace("{typeName}", i18StringsFile[typeName]);
                return translation;
            }
        }

        return i18StringsFile[value] || value;
    };

    //CHANGE THEME
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', siteTheme);
        localStorage.setItem('data-theme', siteTheme);
    }, [siteTheme]);

    //TIME ZONE
    useEffect(() => {
        const localZone = localStorage.getItem('time-zone');
        if (!localZone) {
            localStorage.setItem('time-zone', dayjs.tz.guess());
        }
        dayjs.tz.setDefault(timeZone);

        return () => {
            dayjs.tz.setDefault();
        };
    }, [timeZone]);

    /*LANGUAGE*/
    const setLanguage = (value) => {
        const langParts = value.split('-');
        const localLanguage = langParts[0];

        localStorage.setItem('language', value);
        localStorage.setItem('local-lang', localLanguage);
        setLang(value);
        setLocalLang(localLanguage);
        window.location.reload();
    }
    // SET LANGUAGE*****************
    dayjs.locale(localLang);

    //SET TIME ZONE
    const setTimeZoneForGeneral = (value) => {
        setTimeZone(value);
        localStorage.setItem('time-zone', value);
    }

    /*FAV MATCH*/

    /*FOR FIXTURES*/
    const addAndRemoveFavMatches = async (league, match) => {
        if (favMatches.some(favMatch => favMatch.match.id === match.id)) {
            setFavMatches(
                favMatches.filter(favMatch => favMatch.match.id !== match.id),
            );
        } else {
            const favMatch = {
                leagueDetail: league,
                match,
            };
            setFavMatches([...favMatches, favMatch]);
        }
    };
    const isFav = id => {
        return favMatches.some(favMatch => favMatch.match.id === id);
    };

    /*FOR BET MODE*/
    const addAndRemoveFavBetModeMatches = async (match) => {
        if (favBetModeMatches.some(favMatch => favMatch.id === match.id)) {
            setBetModeMatches(
                favBetModeMatches.filter(favMatch => favMatch.id !== match.id),
            );
        } else {
            console.log(22)
            setBetModeMatches([...favBetModeMatches, match]);
        }
    };
    const isFavBetMode = id => {
        return favBetModeMatches.some(favMatch => favMatch.id === id);
    };

    /*FOR LIVE SCORES*/
    const addAndRemoveFavLiveScoresMatches = async (league, match) => {
        if (favLiveScoresMatches.some(favMatch => favMatch.match.id === match.id)) {
            setFavLiveScoresMatches(
                favLiveScoresMatches.filter(favMatch => favMatch.match.id !== match.id),
            );
        } else {
            const favMatch = {
                leagueDetail: league,
                match,
            };
            setFavLiveScoresMatches([...favLiveScoresMatches, favMatch]);
        }
    };
    const isFavLiveScores = id => {
        return favLiveScoresMatches.some(favMatch => favMatch.match.id === id);
    };

    /*AUTHENTICATION*/

    const login = async (event, redirect) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get("username");
        const password = data.get("password");
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-platform': 'FutbolVerileri', 'x-client-type': clientType, 'x-language': lang},
            body: JSON.stringify({
                username,
                password,
            })
        };
        const response = await fetch('https://santra24.6stats.com/api/user/login', requestOptions);
        const responseObject = await response.json();
        if (responseObject.success) {
            localStorage.setItem('user-token', responseObject.data.token);
            setLoginError(false);
            const detailPayload = {
                method: 'GET',
                headers: {'Authorization': `Bearer ${responseObject.data.token}`},
            }
            const userDetail = await fetch('https://santra24.6stats.com/api/user/me', detailPayload);
            const user = await userDetail.json();
            console.log(user)
            if (user.success === true) {
                localStorage.setItem('user', JSON.stringify(user.data));
                localStorage.setItem('username', user.data.username);
            }
            if (redirect) {
                window.location.href = '/join-premium';
            } else {
                window.location.href = '/';
            }

        } else {
            setLoginError(true)
        }
    };

    const logOut = () => {
        localStorage.removeItem('user-token');
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        window.location.href = '/';
    }

    const setDarkTheme = () => {
        setSiteTheme('dark');
    };

    const setLightTheme = () => {
        setSiteTheme('light');
    };

    const changeTheme = (value) => {
        setSiteTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
    };

    return (
        <AppContext.Provider
            value={{
                changeTheme,
                setDarkTheme,
                setLightTheme,
                siteTheme,
                addAndRemoveFavLiveScoresMatches,
                addAndRemoveFavMatches,
                addAndRemoveFavBetModeMatches,
                favLiveScoresMatches,
                favMatches,
                favBetModeMatches,
                isFavLiveScores,
                isFav,
                isFavBetMode,
                login,
                logOut,
                loginError,
                setLanguage,
                setTimeZoneForGeneral,
                timeZone,
                lang,
                localLang,
                i18String,
                isPro,
                geo,
                clientType
            }}>
            {(i18String && lang !== null) ? children : null}
        </AppContext.Provider>
    );
};
