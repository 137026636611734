import React from 'react';
import './Injure.css';

function Injure(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className='position-container'>
            <div className="injure-container blink">
                <div className="injure">
                    <div className="svg-background">
                        <svg className="match-situation-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                             width="56"
                             height="56" viewBox="0 0 172 172" style={{fill: '#000000'}}>
                            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
                               strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"
                               fontFamily="none" fontSize="none" style={{mixBlendMode: 'normal'}}>
                                <path d="M0,172v-172h172v172z" fill="none"></path>
                                <g fill="#e74c3c">
                                    <path
                                        d="M65.84375,22.57605c-2.28438,0 -4.03125,1.74688 -4.03125,4.03125c0,2.28438 1.74687,4.03125 4.03125,4.03125h40.3125c2.28437,0 4.03125,-1.74687 4.03125,-4.03125c0,-2.28437 -1.74688,-4.03125 -4.03125,-4.03125zM43,35.07135c-9.675,0 -17.46875,7.79375 -17.46875,17.46875v66.9198c0,9.675 7.79375,17.46875 17.46875,17.46875h86c9.675,0 17.46875,-7.79375 17.46875,-17.46875v-66.9198c0,-9.675 -7.79375,-17.46875 -17.46875,-17.46875zM43,43.13385h86c5.24062,0 9.40625,4.16563 9.40625,9.40625v66.9198c0,5.24063 -4.16563,9.40625 -9.40625,9.40625h-86c-5.24063,0 -9.40625,-4.16562 -9.40625,-9.40625v-66.9198c0,-5.24062 4.16562,-9.40625 9.40625,-9.40625zM86,64.5c-3.7625,0 -6.71875,2.95625 -6.71875,6.71875v9.67395h-9.67395c-3.7625,0 -6.71875,2.95625 -6.71875,6.71875c0,3.7625 2.95625,6.71875 6.71875,6.71875h9.67395v9.67657c0,3.7625 2.95625,6.71875 6.71875,6.71875c3.7625,0 6.71875,-2.95625 6.71875,-6.71875v-9.67657h9.67395c3.7625,0 6.71875,-2.95625 6.71875,-6.71875c0,-3.7625 -3.09063,-6.71875 -6.71875,-6.71875h-9.67395v-9.67395c0,-3.7625 -2.95625,-6.71875 -6.71875,-6.71875z"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="injure-info">
                        <div className="injure-match-time">
                            {selectedEvent.time}'
                        </div>
                        <div className="font-size">
                            <span style={{fontWeight: 500}}>{i18n?.injury}</span>
                            <br/>
                            <span className="injure-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Injure;
