import React from 'react';
import './MatchResult.css';
import ImgLabel from '../../../../assets/lmt/img-label-fv@2x.png'
import Counter from "../Counter";

function MatchResult(props) {

    const {
        matchTeam,
        referee,
        manager
    } = props;

    return (
        <div className="general-section">

            {/*Counter*/}

            {
                matchTeam?.status?._id === 0 &&
                <Counter matchTeam={matchTeam} />
            }

            {/*Match Ended*/}

            {
                matchTeam.status._id === 100 || matchTeam.status._id === 31 &&
                <div className="match-result-section">
                    {
                        matchTeam.status._id === 100 &&
                        <div className="match-ended">
                            MAÇ BİTTİ
                        </div>
                    }

                    {
                        matchTeam.status._id === 31 &&
                        <div className="match-ended">
                            İLK YARI
                        </div>
                    }

                    <div className="match-result-container">
                        <div className="match-result">
                            {matchTeam.result.home}
                        </div>
                        <span style={{fontSize: '20px', margin: '0 5px 0 5px'}}>:</span>
                        <div className="match-result">
                            {matchTeam.result.away}
                        </div>
                    </div>
                </div>
            }

            {
                referee &&
                <span><hr className="hr"/></span>
            }

            {
                referee &&
                <div className="referee-container">
                    <span className="referee" style={{fontWeight: 'bold'}}>{referee?.name}</span>
                    <span> <svg className="icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12"
                                height="12"
                                viewBox="0 0 172 172" style={{fill: '#000000'}}><g fill="none" fillRule="nonzero"
                                                                                stroke="none"
                                                                                strokeWidth="1" strokeLinecap="butt"
                                                                                strokeLinejoin="miter"
                                                                                strokeMiterlimit="10"
                                                                                strokeDasharray="" strokeDashoffset="0"
                                                                                fontFamily="none" fontSize="none"
                                                                                style={{mixBlendMode: 'normal'}}><path
                        d="M0,172v-172h172v172z" fill="none"></path><g fill="#cccccc"><path
                        d="M48.44499,6.08887l-10.87598,9.32227l21.5,25.08333l10.87598,-9.32227zM143.61328,14.36133c-1.93499,-0.08062 -3.90583,0.6226 -5.375,2.1276l-11.46386,11.60384c-15.98167,-3.36833 -33.03609,0.645 -46.07942,10.75l26.80501,31.03223l-14.33333,8.95833l-25.15332,-25.93717l-60.84668,83.27051l27.72884,30.45833l58.48112,-36.47722c7.74,3.87 16.48445,6.01888 24.43945,6.01888c29.38333,0 54.18392,-23.72278 54.18392,-53.10612c0,-12.255 -5.01499,-24.14943 -12.61166,-33.53776l4.36719,-7.30664c1.64833,-2.58 1.36111,-5.94945 -0.57389,-8.31445l-14.40332,-16.99284c-1.32583,-1.6125 -3.23005,-2.4669 -5.16505,-2.54753zM21.5,43v14.33333h21.80794v-14.33333z"></path></g></g></svg> <span
                        className="referee-text" style={{fontWeight: 'normal'}}>HAKEM</span></span>
                </div>
            }

            {
                manager &&
                <span><hr className="hr"/></span>
            }


            {
                manager &&
                <div className="manager-and-team-section">
                    <div>
                        <div>
                            <span className="font-size" style={{fontWeight: 'bolder'}}>{manager.home?.name}</span> <br/>
                            <span className="font-size2" style={{fontWeight: 'lighter'}}> TEKNİK DİREKTÖR </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span className="font-size" style={{fontWeight: 'bolder'}}>{manager.away?.name}</span><br/>
                            <span className="font-size2" style={{fontWeight: 'lighter'}}> TEKNİK DİREKTÖR </span>
                        </div>
                    </div>
                </div>
            }

            <span><hr className="hr"/></span>

            <div className="futbol-verileri-container">
                <img className="fv-img" src={ImgLabel} alt=""/>
            </div>

        </div>
    );
}

export default MatchResult;
