import React, {useEffect, useState} from 'react';
import '../Positions/MatchSituation.css'

function MatchSituation(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props

    const [cssClass, setCssClass] = useState('');

    useEffect(() => {
        const cssClassUpdater = () => {
            if (selectedEvent) {
                if (selectedEvent.team === 'home') {
                    if (selectedEvent.situation === 'safe') {
                        setCssClass('home-position-slider low-home')
                    } else if (selectedEvent.situation === 'attack') {
                        setCssClass('home-position-slider mid-home')
                    } else if (selectedEvent.situation === 'dangerous') {
                        setCssClass('home-position-slider high-home')
                    } else {
                        setCssClass('home-position-slider hidden-home')
                    }
                } else {
                    if (selectedEvent.situation === 'safe') {
                        setCssClass('away-position-slider low-away')
                    } else if (selectedEvent.situation === 'attack') {
                        setCssClass('away-position-slider mid-away')
                    } else if (selectedEvent.situation === 'dangerous') {
                        setCssClass('away-position-slider high-away')
                    } else {
                        setCssClass('away-position-slider hidden-away')
                    }
                }
            }
        }

        cssClassUpdater();
    }, [selectedEvent]);

    return (
        <div className='position-container'>
            {/*SAFE HOME*/}
            {
                selectedEvent.team === 'home' && selectedEvent.situation === 'safe' &&
                <div className='position-container'>
                    <div className={cssClass}>
                        <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <polygon points="0, 0 520, 0 568, 172 520, 344 0, 344" fill="url(#svg_1)"/>
                            </g>
                            <defs>
                                <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_1">
                                    <stop offset="0" stopOpacity="0.2" stopColor="#000000"/>
                                    <stop offset="1" stopOpacity="0.65" stopColor="#000000"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={'home-match-situation home-safe'}>
                        <span className="situation-name">{i18n?.matchSituationSafe}</span> <br/>
                        <span className="team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        <br/>
                    </div>
                </div>
            }

            {/*MID HOME*/}
            {
                selectedEvent.team === 'home' && selectedEvent.situation === 'attack' &&
                <div className='position-container'>
                    <div className={cssClass}>
                        <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <polygon points="0, 0 520, 0 568, 172 520, 344 0, 344" fill="url(#svg_1)"/>
                            </g>
                            <defs>
                                <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_1">
                                    <stop offset="0" stopOpacity="0.2" stopColor="#000000"/>
                                    <stop offset="1" stopOpacity="0.65" stopColor="#000000"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={'home-match-situation home-attack'}>
                        <span className="situation-name">{i18n?.matchSituationAttack}</span> <br/>
                        <span className="team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        <br/>
                    </div>
                </div>
            }

            {/*HIGH HOME*/}
            {
                selectedEvent.team === 'home' && selectedEvent.situation === 'dangerous' &&
                <div className='position-container'>
                    <div className={cssClass}>
                        <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <polygon points="0, 0 520, 0 568, 172 520, 344 0, 344" fill="url(#svg_1)"/>
                            </g>
                            <defs>
                                <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_1">
                                    <stop offset="0" stopOpacity="0.2" stopColor="#000000"/>
                                    <stop offset="1" stopOpacity="0.65" stopColor="#000000"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={'home-match-situation home-dangerous'}>
                        <span className="situation-name">{i18n?.matchSituationDangerous}</span> <br/>
                        <span className="team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        <br/>
                    </div>
                </div>
            }

            {/*SAFE AWAY*/}
            {
                selectedEvent.team === 'away' && selectedEvent.situation === 'safe' &&
                <div className='position-container'>
                    <div className={cssClass}>
                        <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <polygon points="0, 172 28, 0 568, 0 568, 344 28, 344" fill="url(#svg_2)"/>
                            </g>
                            <defs>
                                <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_2">
                                    <stop offset="0" stopOpacity="0.65" stopColor="#000000"/>
                                    <stop offset="1" stopOpacity="0.2" stopColor="#000000"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={'away-match-situation away-safe'}>
                        <span className="situation-name">{i18n?.matchSituationSafe}</span> <br/>
                        <span className="team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        <br/>
                    </div>
                </div>
            }

            {/*MID AWAY*/}
            {
                selectedEvent.team === 'away' && selectedEvent.situation === 'attack' &&
                <div className='position-container'>
                    <div className={cssClass}>
                        <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <polygon points="0, 172 28, 0 568, 0 568, 344 28, 344" fill="url(#svg_2)"/>
                            </g>
                            <defs>
                                <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_2">
                                    <stop offset="0" stopOpacity="0.65" stopColor="#000000"/>
                                    <stop offset="1" stopOpacity="0.2" stopColor="#000000"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={'away-match-situation away-attack'}>
                        <span className="situation-name">{i18n?.matchSituationAttack}</span> <br/>
                        <span className="team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        <br/>
                    </div>
                </div>
            }

            {/*MID AWAY*/}
            {
                selectedEvent.team === 'away' && selectedEvent.situation === 'dangerous' &&
                <div className='position-container'>
                    <div className={cssClass}>
                        <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <polygon points="0, 172 28, 0 568, 0 568, 344 28, 344" fill="url(#svg_2)"/>
                            </g>
                            <defs>
                                <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_2">
                                    <stop offset="0" stopOpacity="0.65" stopColor="#000000"/>
                                    <stop offset="1" stopOpacity="0.2" stopColor="#000000"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={'away-match-situation away-dangerous'}>
                        <span className="situation-name">{i18n?.matchSituationDangerous}</span> <br/>
                        <span className="team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        <br/>
                    </div>
                </div>
            }

        </div>
    );
}

export default MatchSituation;
