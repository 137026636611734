import {
    EVENT_STATUSES,
    EVENT_TYPES,
    MAX_EVENT_COUNT,
    UNDEFINED_SECONDS
} from "./eventConstants";

class EventManager {
    match = null
    currentEvents = []
    specialEvents = []
    selectedEvent = null
    time = -1
    status = null
    isLive = false

    resolve(match, events) {
        this.match = match
        this.currentEvents = events
        this.handleStatus()
        this.handleEvents(events)
    }

    handleEvents(eventList) {
        if (!eventList || eventList.length < 1) return;
        let events = eventList.filter(e => e.seconds !== UNDEFINED_SECONDS && EVENT_TYPES.includes(e.type));
        for (let e = 0; e < events.length; e++) {
            let event = events[e];
            for (let et = 0; et < EVENT_TYPES.length; et++) {
                let specialEventType = EVENT_TYPES[et];
                if (event.type === specialEventType) {
                    if (!this.specialEvents.find(e => e._id === event._id)) {
                        this.setSpecialEvent(event);
                    }
                    this.selectedEvent = event;
                    break;
                }
            }
        }
    }

    handleStatus() {
        if (this.match) {
            let status = EVENT_STATUSES[this.match.status._id]
            if (status) {
                this.isLive = status.isLive;
                this.status = status.text;
                this.time = status.time;
                return
            }
        }
        this.status = null;
        this.isLive = true;
        if (this.currentEvents) {
            this.currentEvents.forEach(e => {
                if (e.time > this.time)
                    this.time = e.time;
            })
        }
    }

    setSpecialEvent(event) {
        this.specialEvents.push(event)
        if (this.specialEvents.length > MAX_EVENT_COUNT)
            this.specialEvents.shift()
    }
}



export default EventManager
