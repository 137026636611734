import React from 'react';
import './PositionInfo.css';

function PositionInfo(props) {

    const {
        ballPossession,
        dangerousAttack,
        attack,
        ballSafe
    } = props;

    return (
        <div className="general-section">

            {/*Ball Possession*/}

            <div style={{width: '80%'}}>
                <div className="number-container">
                    <span className="home-number">{ballPossession?.value?.home} <span
                        className="percent-size">%</span></span>
                    <span className="percent-font-size" style={{fontWeight: 'bold'}}>TOPA SAHİP OLMA</span>
                    <span className="away-number">{ballPossession?.value?.away} <span
                        className="percent-size">%</span></span>
                </div>

                <div className="home-percent">
                    <div className="progress just-ball-possession" style={{flex: ballPossession?.value?.home + '%'}}>
                    </div>
                    <div className="away-percent just-ball-possession" style={{flex: ballPossession?.value?.away + '%'}}>
                    </div>
                </div>
            </div>

            {/*Dangerous Attack*/}

            <div style={{width: '80%'}}>
                <div className="number-container">
                    <span className="home-number">
                        {dangerousAttack?.value?.home}
                        <span className="percent-size">%</span>
                    </span>
                    <span className="percent-font-size" style={{fontWeight: 'normal'}}>TEHLİKELİ ATAK</span>
                    <span className="away-number">
                        {dangerousAttack?.value?.away}
                        <span className="percent-size">%</span>
                    </span>
                </div>

                <div className="home-percent">
                    <div className="progress" style={{flex: `${dangerousAttack?.value?.home}` + '%'}}></div>
                    <div className="away-percent" style={{flex: `${dangerousAttack?.value?.away}` + '%'}}></div>
                </div>

                {/*Attack*/}

                <div className="number-container">
                    <span className="home-number">{attack?.value?.home} <span className="percent-size">%</span></span>
                    <span className="percent-font-size" style={{fontWeight: 'normal'}}>HÜCUM</span>
                    <span className="away-number">{attack?.value?.away} <span className="percent-size">%</span></span>
                </div>

                <div className="home-percent">
                    <div className="progress" style={{flex: attack?.value?.home + '%'}}></div>
                    <div className="away-percent" style={{flex: attack?.value?.away + '%'}}></div>
                </div>

                {/*Ball Safe*/}

                <div className="number-container">
                    <span className="home-number">{ballSafe?.value?.home} <span className="percent-size">%</span></span>
                    <span className="percent-font-size" style={{fontWeight: 'normal'}}>TOP GÜVENLİ YERDE</span>
                    <span className="away-number">{ballSafe?.value?.away} <span className="percent-size">%</span></span>
                </div>

                <div className="home-percent">
                    <div className="progress" style={{flex: ballSafe?.value?.home + '%'}}></div>
                    <div className="away-percent" style={{flex: ballSafe?.value?.away + '%'}}></div>
                </div>

            </div>
        </div>
    );
}

export default PositionInfo;
