import React from 'react';
import './Substitution.css';

function Substitution(props) {

    const {
        i18n,
        selectedEvent,
    } = props;

    return (
        <div className="position-container">
            <div className="substitution-container blink">
                <div className="player-out-section">
                    <div className="player-out-container">
                        <div className="substitution-player-name-and-number">
                            <div className="substitution-player-name">
                                {selectedEvent?.playerout?.name}
                            </div>
                            <div className="substitution-number">
                                {selectedEvent?.shirtnumbers?.out} | {selectedEvent?.playerout?.position?.name.toUpperCase()}
                            </div>
                        </div>

                        <div className="arrow-container">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20"
                                   viewBox="0 0 172 172"
                                   style={{fill: '#000000'}}><g fill="none" fillRule="nonzero" stroke="none"
                                                             strokeWidth="1"
                                                             strokeLinecap="butt" strokeLinejoin="miter"
                                                             strokeMiterlimit="10"
                                                             strokeDasharray="" strokeDashoffset="0"
                                                             fontFamily="none"
                                                             fontSize="none" style={{mixBlendMode: 'normal'}}><path
                                  d="M0,172v-172h172v172z" fill="none"></path><g fill="#e74c3c"><path
                                  d="M163.32161,83.07318l-86,-60.91667c-1.0848,-0.76986 -2.51961,-0.86784 -3.71631,-0.25895c-1.18978,0.61589 -1.93864,1.84766 -1.93864,3.18441v32.25h-57.33333c-3.95426,0 -7.16667,3.2124 -7.16667,7.16667v43c0,3.95426 3.2124,7.16667 7.16667,7.16667h57.33333v32.25001c0,1.33675 0.74886,2.56852 1.93864,3.18441c0.5179,0.26595 1.0848,0.39893 1.64469,0.39893c0.72786,0 1.45573,-0.22396 2.07161,-0.65788l86,-60.91667c0.94482,-0.67187 1.51172,-1.76367 1.51172,-2.92546c0,-1.16178 -0.56689,-2.25358 -1.51172,-2.92546z"></path></g></g></svg>
                            </span>
                            <span className="arrow-below-text">{i18n?.substitionOut}</span>
                        </div>
                    </div>
                </div>

                <div className="substitution-text-section">
                    <div className="substitution-container-text">
                        {selectedEvent?.type.toUpperCase()}
                    </div>
                </div>

                <div className="player-in-section">
                    <div className="player-in-container">
                        <div className="arrow-container">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20"
                                 viewBox="0 0 172 172"
                                 style={{fill: '#000000'}}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1"
                                                           strokeLinecap="butt" strokeLinejoin="miter"
                                                           strokeMiterlimit="10"
                                                           strokeDasharray="" strokeDashoffset="0" fontFamily="none"
                                                           fontSize="none" style={{mixBlendMode: 'normal'}}><path
                                d="M0,172v-172h172v172z" fill="none"></path><g fill="#2ecc71"><path
                                d="M157.66667,57.33333h-57.33333v-32.25c0,-1.33675 -0.74886,-2.56852 -1.93864,-3.18441c-0.51516,-0.26215 -1.07943,-0.3985 -1.64253,-0.39893c-0.72975,-0.00056 -1.45751,0.22333 -2.07378,0.65788l-86,60.91667c-0.94482,0.67188 -1.51172,1.76367 -1.51172,2.92546c0,1.16178 0.56689,2.25358 1.51172,2.92546l86,60.91667c0.61589,0.43392 1.34375,0.65788 2.07161,0.65788c0.5599,0 1.12679,-0.13298 1.64469,-0.39893c1.18978,-0.61589 1.93864,-1.84766 1.93864,-3.18441v-32.25h57.33333c3.95426,0 7.16667,-3.2124 7.16667,-7.16667v-43c0,-3.95426 -3.2124,-7.16667 -7.16667,-7.16667z"></path></g></g></svg>
                          </span>
                            <span className="arrow-below-text">{i18n?.substitionIn}</span>
                        </div>

                        <div className="substitution-player-name-and-number">
                            <div className="substitution-player-name">
                                {selectedEvent?.playerin?.name}
                            </div>
                            <div className="substitution-number">
                                {selectedEvent?.playerin?.position?.name.toUpperCase()} | {selectedEvent?.shirtnumbers?.in}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Substitution;
