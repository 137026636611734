import React from 'react';
import {useParams} from "react-router-dom";
import FVLmtV2 from "./FVLmtV2";

function FvLmtV2Container(props) {

    const params = useParams();

    return (
        <div className='fvLmt-general-section'>
            <FVLmtV2 matchId={params.id}/>
        </div>
    );
}

export default FvLmtV2Container;
