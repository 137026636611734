import React, {useEffect, useState} from 'react';
import './Counter.css';

function Counter(props) {

    const {matchTeam} = props;

    const [displayDays, setDisplayDays] = useState(0);
    const [displayHours, setDisplayHours] = useState(0);
    const [displaySecond, setDisplaySecond] = useState(0);
    const [displayMinutes, setDisplayMinutes] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [expired, setExpired] = useState(false);

    useEffect(() => {
        showRemaining();
    }, [])

    let _seconds =  () => 1000;
    let _minutes = () =>
    {
        return _seconds * 60;
    }
    let _hours = () =>
    {
        return _minutes * 60;
    }
    let _days = () =>
    {
        return _hours * 24;
    }

    const formatNum = num => (num < 10 ? "0" + num : num)

    const showRemaining = () => {
        const timer = setInterval(() => {
            const now = new Date();
            const unixTime = matchTeam?._dt?.uts;
            const unixTimeMiliseconds = unixTime * 1000;
            const distance = unixTimeMiliseconds - now.getTime();

            if (distance < 0 ) {
                clearInterval(timer);
                setExpired(true);
                setLoaded(true);
                return;
            }

            const days = Math.floor(distance / _days);
            const hours = Math.floor((distance % _days) / _hours);
            const minutes = Math.floor((distance % _hours) / _minutes);
            const seconds = Math.floor((distance % _minutes) / _seconds);

            setDisplayMinutes(formatNum(minutes));
            setDisplaySecond(formatNum(seconds));
            setDisplayHours(formatNum(hours));
            setDisplayDays(formatNum(days));
            setLoaded(true);
        }, 1000)
    }

    return (
        <div>
            {
                loaded &&
                <div className="counter-section">
                    <div>
                        {
                            !expired ?
                                <h3 className="kickoff-and-match-starting">BAŞLAMA SAATİ</h3>
                                :
                                <h3 className="kickoff-and-match-starting">MAÇ BAŞLIYOR</h3>
                        }
                    </div>

                    <div className="counter-container">
                        <div className="counter-time-container">
                            <span className="counter-time"> {displayDays}</span>
                            <span className="font-size" style={{fontWeight: 'lighter'}}>DAYS</span>
                        </div>
                        <div className="counter-time-container">
                            <span className="counter-time">{displayHours}</span>
                            <span className="font-size" style={{fontWeight: 'lighter'}}>HRS</span>
                        </div>
                        <div className="counter-time-container">
                            <span className="counter-time">{displayMinutes}</span>
                            <span className="font-size" style={{fontWeight: 'lighter'}}>MINS</span>
                        </div>
                        <div className="counter-time-container">
                            <span className="counter-time">{displaySecond}</span>
                            <span className="font-size" style={{fontWeight: 'lighter'}}>SECS</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Counter;
