import React, {useContext, useEffect, useState} from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import './BestTeams.css'
import BestTeamsContent from "./BestTeamsContent";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {bestTeamsMeta} from "../../SEO/SeoMetaTags";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import BestTeamsTableTopScreen from "./BestTeamsTableTopScreen";
import LinearComponent from "../linearComponent/LinearComponent";

function BestTeams(props) {
    const {lang, i18String} = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();

    const [selectedEventType, setSelectedEventType] = useState('');
    const [typeName, setTypeName] = useState('');
    const [statsName, setStatsName] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selection, setSelection] = useState('all');

    const selectionOption = [
        {
            name: `${i18String('All')}`, key: 'all',
        },
        {
            name: `${i18String('Top 10')}`, key: 'top',
        },
        {
            name: `${i18String('Top 50')}`, key: 'priority',
        }
    ]

    const eventTypes = [
        {
            typeName: 'goal-stats',
            index: 0,
            tabs: {
                name: `${i18String('Goals')}`,
                titles: [
                    {
                        type: "Over_2_5_Goals",
                        odd: `${i18String('Over 2.5')}`,
                        index: "goal25"
                    },
                    {
                        type: "Over_1_5_Goals",
                        odd: `${i18String('Over 1.5')}`,
                        index: "goal15"
                    },
                    {
                        type: "Over_0_5_Goals",
                        odd: `${i18String('Over 0.5')}`,
                        index: "goal05"
                    },
                    {
                        type: "Over_3_5_Goals",
                        odd: `${i18String('Over 3.5')}`,
                        index: "goal35"
                    },
                    {
                        type: "Over_4_5_Goals",
                        odd: `${i18String('Over 4.5')}`,
                        index: "goal45"
                    },
                    {
                        type: "BTTS",
                        odd: `${i18String('BTTS')}`,
                        index: "btts"
                    },
                    {
                        type: "Over_0_5_Goals_1H",
                        odd: `${i18String('Over 0.5 1H')}`,
                        index: "goal051h"
                    },
                    {
                        type: "Over_1_5_Goals_1H",
                        odd: `${i18String('Over 1.5 1H')}`,
                        index: "goal151h"
                    },
                    {
                        type: "Over_0_5_Goals_2H",
                        odd: `${i18String('Over 0.5 2H')}`,
                        index: "goal052h"
                    },
                    {
                        type: "Over_1_5_Goals_2H",
                        odd: `${i18String('Over 1.5 2H')}`,
                        index: "goal152h"
                    },
                ]
            }
        },
        {
            typeName: 'corner-stats',
            index: 1,
            tabs: {
                name: `${i18String('Corners')}`,
                titles: [
                    {
                        "type": "Over_9_5_Corners",
                        "odd": `${i18String('Over 9.5')}`,
                        "index": "over95"
                    },
                    {
                        "type": "Over_8_5_Corners",
                        "odd": `${i18String('Over 8.5')}`,
                        "index": "over85"
                    },
                    {
                        "type": "Over_7_5_Corners",
                        "odd": `${i18String('Over 7.5')}`,
                        "index": "over75"
                    },
                    {
                        "type": "Over_6_5_Corners",
                        "odd": `${i18String('Over 6.5')}`,
                        "index": "over65"
                    },
                    {
                        "type": "Over_10_5_Corners",
                        "odd": `${i18String('Over 10.5')}`,
                        "index": "over105"
                    },
                    {
                        "type": "Over_11_5_Corners",
                        "odd": `${i18String('Over 11.5')}`,
                        "index": "over115"
                    },
                ]
            }
        },
        {
            typeName: 'card-stats',
            index: 2,
            tabs: {
                name: `${i18String('Cards')}`,
                titles: [
                    {
                        "type": "Over_0_5_Cards",
                        "odd": `${i18String('Over 0.5')}`,
                        "index": "over05"
                    },
                    {
                        "type": "Over_1_5_Cards",
                        "odd": `${i18String('Over 1.5')}`,
                        "index": "over15"
                    },
                    {
                        "type": "Over_2_5_Cards",
                        "odd": `${i18String('Over 2.5')}`,
                        "index": "over25"
                    },
                    {
                        "type": "Over_3_5_Cards",
                        "odd": `${i18String('Over 3.5')}`,
                        "index": "over35"
                    },
                    {
                        "type": "Over_4_5_Cards",
                        "odd": `${i18String('Over 4.5')}`,
                        "index": "over45"
                    },
                    {
                        "type": "Over_5_5_Cards",
                        "odd": `${i18String('Over 5.5')}`,
                        "index": "over55"
                    },
                    {
                        "type": "Over_6_5_Cards",
                        "odd": `${i18String('Over 6.5')}`,
                        "index": "over65"
                    },
                ]
            }
        }
    ]

    /*FOR SEO*/
    useEffect(() => {
        if (params.stats && params.type) {
            function capitalizeFirstLetter(str) {
                return str.replace(/\b\w+\b/g, function (word) {
                    const ff = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
                    if (ff.find(item => word[0] === item)) {
                        return word.substring(0, 1) + word.charAt(1).toUpperCase() + word.substring(2);
                    } else {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    }
                });
            }

            setSelectedEventType(params.stats.toLowerCase());
            setSelectedType(capitalizeFirstLetter(params.type).replace(/-/g, '_'));

        } else {
            setSelectedEventType("goal-stats");
            setSelectedType('Over_2_5_Goals');
        }
    }, []);

    useEffect(() => {
        if (!params.stats && !params.type) {
            setTypeName(i18String('Goals'));
            setStatsName(i18String('Over 2.5'));
        } else {
            setTypeName(params.stats);
            setStatsName(params.type);
            /*const setTypeAndStatsFunc = eventTypes.map((event) => {
                if (event.typeName === params.stats) {
                    setTypeName(event.tabs.name);
                    return event.tabs.titles.map((item) => {
                        const ff = item.type.replace(/_/g, '-').toLowerCase();
                        if (ff === params.type) {
                            setStatsName(item.odd);
                        }
                    })
                }
            });*/
        }
    },[lang, params.stats, params.type]);

    /*SET URL*/
    useEffect(() => {
        navigate(`/best-teams/${selectedEventType.replace(/_/g, '-').toLowerCase()}/${selectedType.replace(/_/g, '-').toLowerCase()}`);
    }, [selectedType, selectedEventType]);

    const onClickEventType = (type) => {
        setSelectedEventType(type.typeName);
        setTypeName(type.tabs.name);

        const eventType = eventTypes.find((et) => et.typeName === type.typeName);
        setSelectedType(eventType.tabs.titles[0].type);
        /*const ff = eventType.tabs.titles[0].type.replace(/_/g, '-').toLowerCase();
        setStatsName(ff);*/
    }

    const onClickType = (tabType) => {
        console.log('tabType', tabType);
        setSelectedType(tabType.type);
        setStatsName(tabType.odd);
    }

    const eventTypeIndex = eventTypes.findIndex((et) => et.typeName === selectedEventType)
    const eventType = eventTypes[eventTypeIndex];
    const subTabIndex = eventType?.tabs?.titles.findIndex((st) => st.type === selectedType)

    const activeStatButton = (item) => {
        if (item.typeName === selectedEventType) {
            return {
                background: 'var(--H3Dark1)',
                color: 'var(--H3Light0)',
            }
        } else {
            return {}
        }
    }

    const activeSelectionButton = (item) => {
        if (item.key === selection) {
            return {
                background: 'var(--H3Grey05)',
                color: 'var(--H3Dark0)',
            }
        } else {
            return {}
        }
    }

    return (
        <DocumentMeta {...bestTeamsMeta(typeName, statsName)[lang]}>
            <div className="const-width best-teams-section  const-width-content">
                <div style={{padding: '5px'}}>
                    <LinearComponent />
                </div>

                <BestTeamsTableTopScreen statsName={statsName} typeName={typeName}/>

                <div className='best-teams-type-button-and-date-container'>
                    <div className='best-teams-type-button-container'>
                        {
                            eventTypes.map((item) => {
                                return (
                                    <div
                                        style={activeStatButton(item)}
                                        className="best-teams-type-button"
                                        onClick={() => onClickEventType(item)}
                                        key={item.tabs.typeName}
                                    >
                                        {item.tabs.name}
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className='filter-selection-top-and-priority-container' style={{justifyContent: 'flex-end'}}>
                        <div className="stats-button-container" style={{border: '1px solid var(--H3MidGrey1)'}}>
                            {
                                selectionOption.map((item) => {
                                    return (
                                        <div
                                            style={activeSelectionButton(item)}
                                            className="stat-button selection-text"
                                            onClick={() => setSelection(item.key)}
                                            key={item.key}
                                        >
                                            {item.name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>


                <div className="best-teams-bottom-tabs-section">
                    <Tabs
                        className="best-teams-bottom-tabs-container"
                        variant="scrollable"
                        scrollButtons="auto"
                        value={subTabIndex}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: 'var(--H3Dark1)'
                            }
                        }}
                    >
                        {
                            eventType?.tabs?.titles?.map((tabTitle, index) => {
                                return (<Tab
                                    key={tabTitle.type}
                                    className="best-teams-bottom-tabs"
                                    onClick={() => onClickType(tabTitle)}
                                    value={index}
                                    label={tabTitle.odd}
                                />)
                            })
                        }
                    </Tabs>
                </div>
                <BestTeamsContent  key={selectedType} type={selectedType} selection={selection} />
            </div>
        </DocumentMeta>
    );
}

export default BestTeams;
