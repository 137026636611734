import React, {useContext, useEffect, useState} from 'react';
import '../FVLmt.css'
import MatchSituation from "./Positions/MatchSituation.js";
import Throwing from "./Positions/Throwing";
import Freekick from "./Positions/Freekick";
import CornerAnimation from "./Positions/CornerAnimation";
import GoalKick from "./Positions/GoalKick";
import Cards from "./Positions/Cards";
import Injure from "./Positions/Injure";
import InjuryTime from "./Positions/InjuryTime";
import ShotOnTarget from "./Positions/ShotOnTarget";
import Offside from "./Positions/Offside";
import Substitution from "./Positions/Substitution";
import Statistics from "./Statistics.js";
import './PositionIndicators.css';
import Goal from "./Positions/Goal";
import {AppContext} from "../../../context/AppContext";
import GoalAnimationV2Away from "./Positions/GoalAnimationV2Away";
import GoalAnimationV2Home from "./Positions/GoalAnimationV2Home";
import GoalAnimationV3Away from "./Positions/GoalAnimationV3Away";
import GoalAnimationV3Home from "./Positions/GoalAnimationV3Home";
import MatchSituationMackolik from "./Positions/MatchSituationMackolik";

function PositionIndicators(props) {

    const {lang} = useContext(AppContext);

    const {
        selectedEvent,
        matchTeam,
        referee,
        manager,
        stadium,
        corner,
        ballPossession,
        dangerousAttack,
        attack,
        ballSafe,
        cards,
        type
    } = props;
    const [strings, setStrings] = useState(null);

    const i18n = {
        tr: {
            positionIndicators: {
                yCard: 'Sarı Kart',
                rCard: 'Kırmızı Kart',
                corner: 'Korner',
                freekick: 'Serbest Vuruş',
                goal: 'Gol',
                goalKick: 'Kale Vuruşu',
                injury: 'Sakatlık',
                injuryTime: 'Uzatma Süresi',
                matchSituationSafe: 'Güvenli Bölge',
                matchSituationAttack: 'Atak',
                matchSituationDangerous: 'Tehlikeli Atak',
                offside: 'Ofsayt',
                shotOnTarget: 'İsabetli Şut',
                substitionIn: 'Giren',
                substitionOut: 'Çıkan',
                throwin: 'Taç',
            },
        },
        en: {
            positionIndicators: {
                yCard: 'Yellow Card',
                rCard: 'Red Card',
                corner: 'Corner',
                freekick: 'Freekick',
                goal: 'Goal',
                goalKick: 'Gaol Kick',
                injury: 'Injury',
                injuryTime: 'Injury Time',
                matchSituationSafe: 'Safe Ball',
                matchSituationAttack: 'Attack',
                matchSituationDangerous: 'Dangerous Attack',
                offside: 'Offside',
                shotOnTarget: 'Shot On Target',
                substitionIn: 'In',
                substitionOut: 'Out',
                throwin: 'Throwin',
            },
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (lang) {
            setStrings(i18n[lang]);
        }
    }, [])

    return (
        <div style={{height: '100%'}}>
            {
                !!matchTeam &&
                <div className="position-container">
                    <div className='lmt-card-container'>
                        {
                            matchTeam.status._id === 0 || matchTeam.status._id === 31 || matchTeam.status._id === 100 &&
                            <Statistics
                                selectedEvent={selectedEvent} matchTeam={matchTeam} referee={referee} manager={manager}
                                stadium={stadium} corner={corner}
                                ballPossession={ballPossession} dangerousAttack={dangerousAttack} attack={attack}
                                ballSafe={ballSafe}
                                cards={cards}
                            ></Statistics>
                        }
                    </div>

                    {
                        matchTeam.status._id !== 31 && matchTeam.status._id !== 100 &&
                        <div className="position-container">
                            {/*MATCHSITUATION*/}
                            {
                                type === 'matchsituation' &&
                                <MatchSituation i18n={strings?.positionIndicators}
                                                selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*MATCHSITUATION MACKOLIK VIEW*/}
                            {/*{
                                type === 'matchsituation' &&
                                <MatchSituationMackolik i18n={strings?.positionIndicators}
                                                selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }*/}
                            {/*THROWIN*/}
                            {
                                type === 'throwin' &&
                                <Throwing i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*FREEKICK*/}
                            {
                                type === 'freekick' &&
                                <Freekick i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*CORNER*/}
                            {
                                type === 'corner' &&
                                <CornerAnimation i18n={strings?.positionIndicators}
                                                 selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*GOAL*/}
                            {
                                type === 'goal' &&
                                <Goal i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*GOAL V2 HOME AWAY*/}
                            {/*{
                                type === 'goal' && selectedEvent?.team === 'away' &&
                                <GoalAnimationV2Away i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {
                                type === 'goal' && selectedEvent?.team === 'home' &&
                                <GoalAnimationV2Home i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }*/}
                            {/*GOAL V3 HOME AWAY*/}
                            {/*{
                                type === 'goal' && selectedEvent?.team === 'away' &&
                                <GoalAnimationV3Away i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {
                                type === 'goal' && selectedEvent?.team === 'home' &&
                                <GoalAnimationV3Home i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }*/}
                            {/*GOAL KICK*/}
                            {
                                type === 'goal_kick' &&
                                <GoalKick i18n={strings?.positionIndicators}
                                          selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*CARDS*/}
                            {
                                type === 'card' &&
                                <Cards i18n={strings?.positionIndicators}
                                       selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*INJURE*/}
                            {
                                type === 'injury' &&
                                <Injure i18n={strings?.positionIndicators}
                                        selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*INJURY TIME*/}
                            {
                                matchTeam?.timeinfo?.injurytime &&
                                <InjuryTime i18n={strings?.positionIndicators}
                                            selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*SHOT ON TARGET*/}
                            {
                                type === 'shotontarget' &&
                                <ShotOnTarget i18n={strings?.positionIndicators}
                                              selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*OFFSIDE*/}
                            {
                                type === 'offside' &&
                                <Offside i18n={strings?.positionIndicators}
                                         selectedEvent={selectedEvent} matchTeam={matchTeam}/>
                            }
                            {/*SUBSTITUTION*/}
                            {
                                type === 'substitutions' &&
                                <Substitution i18n={strings?.positionIndicators}
                                              selectedEvent={selectedEvent}/>
                            }

                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default PositionIndicators;
