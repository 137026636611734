import React, {useEffect, useState} from 'react';
import '../FVLmt.css'
import axios from "axios";
import EventManager from "../utils/eventManager";
import {fetchMatchDetailForLmt} from "../../../api";
import PositionIndicators from "../components/PositionIndicators.js";

function FvLmtV2(props) {

    const {matchId} = props;

    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [matchTeam, setMatchTeam] = useState(null);
    const [matchStatus, setMatchStatus] = useState(null);
    const [matchTime, setMatchTime] = useState(-1);
    const [matchInfo, setMatchInfo] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [ballCoordinates, setBallCoordinates] = useState(null);
    const [coordinate, setCoordinate] = useState(null);
    const [referee, setReferee] = useState(null);
    const [manager, setManager] = useState(null);
    const [stadium, setStadium] = useState(null);
    const [ballPossession, setBallPossession] = useState(null);
    const [dangerousAttack, setDangerousAttack] = useState(null);
    const [attack, setAttack] = useState(null);
    const [ballSafe, setBallSafe] = useState(null);
    const [corner, setCorner] = useState(null);
    const [cards, setCards] = useState(null);
    const [intervall, setIntervall] = useState(null);
    const [timeout, setTimeout] = useState(1000);
    const [eventManager, setEventManager] = useState(new EventManager());
    const [radarId, setRadarId] = useState(matchId);
    const [type, setType] = useState(null);

    const isOldUrl = `${matchId}`.length === 8
    useEffect(() => {
        if (isOldUrl) {
            fetchFeedV1();
            fetchMatchInfo();
            fetchDetail();
        } else {
            fetchFeed();
        }
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isOldUrl) {
                fetchFeedV1();
            } else {
                fetchFeed();
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        setTime();
        const intervalId = setInterval(() => {
            setTime();
            ball();
        }, 1000);

        return () => clearInterval(intervalId);
    }, [matchTeam])

    let calculateBallPosition = () => {
        if (selectedEvent && selectedEvent.type !== 'goal' && matchTeam.status._id !== 31 && matchTeam.status._id !== 100 && selectedEvent.type !== 'corner') {
            if (selectedEvent.type === 'goal_kick' && selectedEvent.team === 'home') {
                return {show: true, x: 30, y: 175}
            }
            if (selectedEvent.type === 'goal_kick' && selectedEvent.team === 'away') {
                return {show: true, x: 535, y: 175}
            }
            if (selectedEvent.type === 'corner' && selectedEvent.Y < 7) {
                return {show: true, x: selectedEvent.X * 5, y: 3}
            }
            if (selectedEvent.type === 'corner' && selectedEvent.Y > 85) {
                return {show: true, x: selectedEvent.X * 5, y: 345}
            }
            if (selectedEvent.type === 'throwin' && selectedEvent.Y <= 7) {
                return {show: true, x: selectedEvent.X * 5.5, y: 3}
            }
            if (selectedEvent.type === 'throwin' && selectedEvent.Y >= 93) {
                return {show: true, x: selectedEvent.X * 5.5, y: 345}
            }
            if (selectedEvent.X && selectedEvent.Y) {
                let fixedX;
                let fixedY;
                if (selectedEvent.X < 7) {
                    fixedX = 5;
                } else if (selectedEvent.X > 93) {
                    fixedX = 398;
                } else {
                    fixedX = coordinate?.X * 5.7;
                }
                if (selectedEvent.Y <= 8) {
                    fixedY = 5;
                } else if (selectedEvent.Y > 91) {
                    fixedY = 345;
                } else {
                    fixedY = Math.floor(coordinate?.Y * 3.5)
                }
                return {show: true, x: fixedX, y: fixedY};
            } else {
                return {show: false}
            }
        } else {
            return {show: false}
        }
    }

    const ballPosition = calculateBallPosition();

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    const describeArc = (x, y, radius, startAngle, endAngle) => {
        const start = polarToCartesian(x, y, radius, endAngle);
        const end = polarToCartesian(x, y, radius, startAngle);

        const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

        const d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
            "L", x, y,
            "L", start.x, start.y
        ].join(" ");
        return d;
    }

    const pointDirection = (x1, y1, x2, y2) => {
        return Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
    }

    const getPathD = (xBase, yBase, xBall, yBall, radius) => {
        let degree = pointDirection(xBase, yBase, xBall, yBall);
        degree -= 90;
        if (degree < 0) {
            degree += 360;
        }
        return describeArc(xBall, yBall, radius, degree - 16, degree + 16);
    }
    let segmentPosition = () => {
        if (selectedEvent && ballPosition.show && selectedEvent.type !== 'matchsituation' && selectedEvent.type !== 'offside' && selectedEvent.type !== 'injury' && selectedEvent.type !== 'shotontarget' && selectedEvent.type !== 'corner') {
            const basePosition = selectedEvent.team === 'home' ? {x: 500, y: 170} : {x: 0, y: 170};
            return getPathD(basePosition.x, basePosition.y, ballPosition.x, ballPosition.y, 60);
        } else {
            return null;
        }

    }
    const segmentPoss = segmentPosition();

    const ball = () => {
        if (allEvents) {
            let i = 0;
            for (i; i < allEvents.length; i++) {
                if (allEvents[i].type === 'ballcoordinates') {
                    setBallCoordinates(allEvents[i]);
                }
            }
        }
        if (!!selectedEvent && ['freekick', 'offside', 'shotontarget'].includes(selectedEvent.type)) {
            setCoordinate(selectedEvent);
        } else if (ballCoordinates) {
            setCoordinate(ballCoordinates?.coordinates[0]);
        }
    }

    const fetchFeedV1 = async () => {
        /*const response = await axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/gismo/match_timelinedelta/${matchId}`);
        let match = response.data?.doc[0]?.data?.match;
        let allEvents = response.data?.doc[0]?.data?.events;
        eventManager.resolve(match, allEvents);
        setMatchTeam(match)
        setMatchStatus(eventManager.status);
        setMatchTime(eventManager.time);
        setSelectedEvent(eventManager.selectedEvent);
        setEvents(eventManager.specialEvents);
        setAllEvents(response.data?.doc[0]?.data?.events);*/
        //this.matchStatus = getMatchStatus(response.data?.doc[0]?.data.match.status._id, response.data?.doc[0]?.data?.events, this.matchTime)
        //this.handleNewEventList(response.data?.doc[0]?.data?.events);
    }

    const fetchFeed = async () => {
        /*const data = await fetchMatchDetailForLmt(matchId);
        if (data.data.data) {
            setRadarId(data.data.data.radarId);
        }
        const response = await axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/gismo/match_timelinedelta/${radarId}`);
        let match = response.data?.doc[0]?.data?.match;
        let allEvents = response.data?.doc[0]?.data?.events;
        eventManager.resolve(match, allEvents);
        setMatchTeam(match);
        setMatchStatus(eventManager.status);
        setMatchTime(eventManager.time);
        setSelectedEvent(eventManager.selectedEvent);
        setEvents(eventManager.specialEvents);
        setAllEvents(response.data?.doc[0]?.data?.events);*/
        fetchMatchInfo();
        fetchDetail();
    }

    const fetchMatchInfo = async () => {
        if (radarId) {
            /*const response = await axios.get(`https://ls.fn.sportradar.com/common/en/Etc:UTC/gismo/match_info/${radarId}`);
            let info = response.data?.doc[0]?.data
            setReferee(info.referee);
            setManager(info.manager);
            setStadium(info.stadium);*/
        }
    }

    const fetchDetail = async () => {
        if (radarId) {
            /*const response = await axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/gismo/match_detailsextended/${radarId}`);
            let info = response.data?.doc[0]?.data;
            if (info.values) {
                setCorner(info.values[124]);
                setCards(info.values[40]);
                setBallPossession(info.values[110]);
                setDangerousAttack(info.values.dangerousattackpercentage);
                setAttack(info.values.attackpercentage);
                setBallSafe(info.values.ballsafepercentage);
            }*/
        }
    }

    const isExpandedEvent = () => {
        if (selectedEvent) {
            return ['corner', 'goal'].includes(selectedEvent.type)
        }
    }

    const setTime = () => {
        if (matchTeam) {
            let seconds = (new Date().getTime() / 1000) - matchTeam.ptime;
            if (matchTeam.status._id === 7) {
                const minute = 45 * 60
                seconds = minute + seconds
            }

            setSec(pad(parseInt(seconds % 60)));
            setMin(pad(parseInt(seconds / 60)));
        }
    }


    const pad = (val) => {
        let valString = val + "";
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }

    return (
        <div className="a">
            <div className="row">
                <div className="col-12">
                    <div className="fv-lmt-wrapper">

                        {/*BG NOISE*/}
                        <div className="fv-lmt-bg-noise"></div>

                        {/*STRIPES*/}
                        <div className="fv-lmt-stripes"></div>

                        {/*OVERLAY Z-1*/}
                        <div className="fv-lmt-overlay"></div>

                        {/*CONTENT Z-2*/}
                        <div
                            className={isExpandedEvent() ? 'fv-lmt-content-wrapper-expanded' : 'fv-lmt-content-wrapper'}>
                            {
                                !!matchTeam && !!cards &&
                                <PositionIndicators type={selectedEvent?.type} selectedEvent={selectedEvent}
                                                    matchTeam={matchTeam} referee={referee}
                                                    manager={manager} stadium={stadium} corner={corner}
                                                    ballPossession={ballPossession}
                                                    dangerousAttack={dangerousAttack} attack={attack}
                                                    ballSafe={ballSafe} cards={cards}
                                />
                            }
                        </div>

                        {/*Time and Round*/}
                        <div>
                            {
                                !!matchTeam &&
                                <div className="time-container">
                                    <div className="time">
                                        <div className="round-container">
                                            {
                                                matchTeam.status._id === 0 &&
                                                <div className="round-p">
                                                    {matchTeam.status.name}
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 6 &&
                                                <div className="round-p">
                                                    {matchTeam.p}st
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 41 &&
                                                <div className="round-p">
                                                    {matchTeam.status.name}
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 31 &&
                                                <div className="round-p">
                                                    {matchTeam.status.name}
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 80 &&
                                                <div className="round-p">
                                                    {matchTeam.status.name}
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 100 &&
                                                <div className="round-p lmt-match-result">
                                                    {matchTeam.status.name}
                                                    <br/>
                                                    <div>
                                                        {matchTeam.result.home} : {matchTeam.result.away}
                                                    </div>
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 7 &&
                                                <div className="round-p">
                                                    {matchTeam.p}nd
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 42 &&
                                                <div className="round-p">
                                                    {matchTeam.p}nd
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 32 &&
                                                <div className="round-p lmt-match-result">
                                                    {matchTeam.status.name}
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id === 120 &&
                                                <div className="round-p lmt-match-result">
                                                    {matchTeam.status.name}
                                                </div>
                                            }

                                            {
                                                matchTeam.status._id !== 31 && matchTeam.status._id !== 100 && matchTeam.status._id !== 32 && matchTeam.status._id !== 0 && matchTeam.status._id !== 120 &&
                                                <span className="vertical-line">
                                                        |
                                                    </span>
                                            }

                                            {
                                                matchTeam.status._id !== 31 && matchTeam.status._id !== 100 && matchTeam.status._id !== 32 && matchTeam.status._id !== 0 && matchTeam.status._id !== 120 &&
                                                <div className="time-min-sec">
                                                    {min}:{sec}
                                                </div>
                                            }

                                            {
                                                matchTeam.timeinfo.injurytime !== null && matchTeam.status._id !== 31 && matchTeam.status._id !== 100 &&
                                                <div className="injury-time-without-margin">
                                                    <span>+</span> <span>{matchTeam.timeinfo.injurytime}</span>
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            <div className="score-container">
                                                {
                                                    matchTeam.status._id !== 100 &&
                                                    <div>
                                                        {matchTeam.result.home} - {matchTeam.result.away}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        {/*LINES*/}

                        {/*<div id="asd">
                                <div id="animation-background">
                                    <img style="width: 100%; height: 100%;" src="@/assets/images/pitch.jpg" alt="pitch">

                                </div>*/}

                        <div className="fv-lmt-pitch-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 568 352"
                                 style={{stroke: 'rgb(255, 255, 255)', marginBottom: '-5px'}}>
                                <path vectorEffect="non-scaling-stroke" strokeWidth="3" fill="none"
                                      d="M10,10h548v332H10V10z"></path>
                                <path vectorEffect="non-scaling-stroke" strokeWidth="3" fill="none"
                                      d="M83.5,211c12-7.2,20-20.2,20-35s-8-27.8-20-35 M10.5,87h73v178h-73 M557.5,87h-73v178h73 M484.5,211c-12-7.2-20-20.2-20-35s8-27.8,20-35"></path>
                                <path vectorEffect="non-scaling-stroke" strokeWidth="3" fill="none"
                                      d="M10.5,139h29v74h-29 M557.5,139h-29v74h29 M284,10v332"></path>
                                <circle vectorEffect="non-scaling-stroke" strokeWidth="3" fill="none" cx="284"
                                        cy="176"
                                        r="44"></circle>
                                <path vectorEffect="non-scaling-stroke" strokeWidth="3" fill="none"
                                      d="M10,156H4v40h6"></path>
                                <path vectorEffect="non-scaling-stroke" strokeWidth="3" fill="none"
                                      d="M558,156h6v40h-6"></path>
                                <line vectorEffect="non-scaling-stroke" strokeWidth="7" fill="none"
                                      strokeLinecap="round"
                                      x1="284"
                                      y1="176" x2="284" y2="176"></line>
                                <line vectorEffect="non-scaling-stroke" strokeWidth="5" fill="none"
                                      strokeLinecap="round"
                                      x1="59"
                                      y1="176" x2="59" y2="176"></line>
                                <line vectorEffect="non-scaling-stroke" strokeWidth="5" fill="none"
                                      strokeLinecap="round"
                                      x1="509"
                                      y1="176" x2="509" y2="176"></line>
                                <path vectorEffect="non-scaling-stroke" strokeWidth="3" fill="none"
                                      d="M10,18c4.4,0,8-3.6,8-8 M18,342c0-4.4-3.6-8-8-8 M550,342c0-4.4,3.6-8,8-8 M558,18c-4.4,0-8-3.6-8-8"></path>
                                {
                                    ballPosition.show && selectedEvent.type === 'matchsituation' &&
                                    <circle
                                        cx={ballPosition.x}
                                        cy={ballPosition.y} r="0.5rem" stroke="white"
                                        strokeWidth="7" fill="black"/>
                                }
                                {
                                    ballPosition.show && selectedEvent.type === 'throwin' &&
                                    <circle
                                        cx={ballPosition.x}
                                        cy={ballPosition.y} r="0.5rem" stroke="white"
                                        strokeWidth="7" fill="white"/>
                                }

                                {
                                    ballPosition.show && selectedEvent.type !== 'throwin' && selectedEvent.type !== 'matchsituation' &&
                                    <circle
                                        cx={ballPosition.x}
                                        cy={ballPosition.y} r="0.5rem" stroke="white"
                                        strokeWidth="7" fill="red"/>
                                }

                                {
                                    segmentPoss &&
                                    <path className="blink-animation" d={segmentPoss}/>
                                }
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FvLmtV2;
