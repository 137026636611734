import React from 'react';

function Contact(props) {
    return (
        <div className="const_width">
            <div className="content_container">
                <p className="heading">İletişim</p>
                <div className="mx-auto">
                    <p className="content-text">
                        <strong>FutbolVerileri.com</strong>'da yer alan içerikler ve diğer konular hakkında her türlü
                        soru, görüş ve
                        önerileriniz için bize ulaşabilirsiniz.
                    </p>
                    <br/>
                        <p className="content-text">
                            <strong>E-Posta:</strong><span className="link_color"><a
                            href="mailto:support@futbolverileri.com"> support@futbolverileri.com</a>
                    </span>
                        </p>
                        <p className="content-text">
                            <strong>Adres:</strong> 27 Old Gloucester Street, London, England, WC1N 3AX
                        </p>
                        <p className="content-text">
                            <strong>Twitter:</strong> <span className="link_color"><a
                            href="https://twitter.com/futbolverileri" target="_blank">
                        https://twitter.com/futbolverileri</a></span>
                        </p>
                        <p className="content-text">
                            <strong>Facebook:</strong> <span className="link_color"><a
                            href="https://www.facebook.com/futbolverileri/" target="_blank">
                        https://www.facebook.com/futbolverileri/</a></span>
                        </p>
                        <p className="content-text">
                            <strong>Instagram:</strong> <span className="link_color"><a
                            href="https://www.instagram.com/futbolverileri/" target="_blank">
                        https://www.instagram.com/futbolverileri/</a></span>
                        </p>
                </div>
            </div>
        </div>
    );
}

export default Contact;
