import React, {useEffect, useState} from 'react';
import './Cards.css';
import YellowCard from '../../../../assets/lmt/yellowCard.svg';
import RedCard from '../../../../assets/lmt/redCard.svg';

function Cards(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className='position-container'>
            <div className="cards-container blink">
                <div className="cards">
                    <div>
                        {
                            selectedEvent.card === 'yellow' ?
                                <img className='match-situation-icon' src={YellowCard} alt=""/>
                                :
                                <img className='match-situation-icon' src={RedCard} alt=""/>
                        }
                    </div>
                    <div className="cards-info">
                        <div className={selectedEvent?.card === 'yellow' ? 'match-time yellow' : 'match-time red'}>
                            {selectedEvent.time}'
                        </div>

                        <div className="cards-font-size">
                        <span style={{fontWeight: 500}}>
                            {
                                selectedEvent.card === 'yellow' ?
                                    <span>{i18n?.yCard}</span>
                                    :
                                    <span>{i18n?.rCard}</span>
                            }
                        </span>
                            <br/> <span
                            className="cards-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default Cards;
