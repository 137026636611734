import React from 'react';
import goalSvg from '../../../../assets/lmt/score.svg';
import './Goal.css';

function Goal(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className="position-container">

            {
                selectedEvent.team === 'away' &&
                <div className="away-goal-section">
                    <div className="away-goal-animation-container">
                        <img src={goalSvg} className="match-situation-icon goal-img-reverse" alt="Goal"/>
                    </div>

                    <div className="away-goal-container">
                        <div className="goal-font-size">
                            {matchTeam.teams[selectedEvent.team].name}
                        </div>
                        <div>
                            <span className="font-size2">{i18n?.goal}</span>
                        </div>
                    </div>
                </div>
            }

            {
                selectedEvent.team === 'home' &&
                <div className="home-goal-section">
                    <div className="home-goal-container">
                        <div className="goal-font-size">
                            {matchTeam.teams[selectedEvent.team].name}
                        </div>
                        <div className="away-goal-text">
                            <span className="font-size2">{i18n?.goal}</span>
                        </div>
                    </div>

                    <div className="home-goal-animation-container">
                        <img src={goalSvg} className="match-situation-icon" alt="Goal"/>
                    </div>
                </div>
            }

        </div>
    );
}

export default Goal;
