import React, {useEffect, useState} from 'react';
import img from "../../../assets/icons/instagram.png";
import liveIcon from "../../../assets/icons/clock.png";
import logo from "../../../assets/icons/logo.png";
import searchIcon from "../../../assets/icons/searchIcon.png";
import calenderIcon from "../../../assets/icons/calender-icon.png";
import {Link} from "react-router-dom";
import MobileHamburgerMenu from "../mobile-hamburger-menu/MobileHamburgerMenu";

function LiveScoresScreenMobileHeader(props) {

    const [lsLiveStatus, setLSLiveStatus] = useState(window.setLSLiveStatus || false);
    const [lsShownDateStatus, setLSShowDateStatus] = useState(window.setLSShowDateStatus || false);
    const [lsShownSearchStatus, setLSShowSearchStatus] = useState(window.setLSShowSearchStatus || false);

    useEffect(() => {
        const handleLiveChange = () => {
            setLSLiveStatus(window.setLSLiveStatus);
        };
        const handleShownDate = () => {
            setLSShowDateStatus(window.setLSShowDateStatus);
        };
        const handleSearch = () => {
            setLSShowSearchStatus(window.setLSShowSearchStatus);
        };

        // window'daki toggleChange event'ini dinle
        window.addEventListener('liveChange', handleLiveChange);
        window.addEventListener('shownDateStatus', handleShownDate);
        window.addEventListener('shownSearchStatus', handleSearch);

        // Component unmount olduğunda event listener'ı temizle
        return () => {
            window.removeEventListener('liveChange', handleLiveChange);
            window.removeEventListener('shownDateStatus', handleShownDate);
            window.removeEventListener('shownSearchStatus', handleSearch);
        };
    }, []);

    const liveIconStatus = () => {
        window.setLSLiveStatus = !window.setLSLiveStatus; // Değeri değiştir
        window.dispatchEvent(new Event('liveChange')); // Event'i tetikle
    };

    const dateIconStatus = () => {
        window.setLSShowDateStatus = !window.setLSShowDateStatus; // Değeri değiştir
        window.dispatchEvent(new Event('shownDateStatus')); // Event'i tetikle
    };

    const searchIconStatus = () => {
        window.setLSShowSearchStatus = !window.setLSShowSearchStatus; // Değeri değiştir
        window.dispatchEvent(new Event('shownSearchStatus')); // Event'i tetikle
    };

    return (
        <div className='stats-screen-header-general-container'>
            <MobileHamburgerMenu />
            <div className='stats-screen-header-container'>
                {/*<button onClick={liveIconStatus}>Live</button>*/}
                {
                    lsLiveStatus ?
                        <div style={{position: 'static'}}>
                            <img onClick={liveIconStatus} className='mobile-left-header-icons live-on-icon' src={liveIcon} alt="Live"/>
                        </div>
                        :
                        <div style={{position: 'static'}}>
                            <img onClick={liveIconStatus} className='mobile-left-header-icons' src={liveIcon} alt="Live"/>
                        </div>
                }

                <div className='logo-container'>
                    <Link to={'/'} className="site-logo-text">
                        <img className={'logo'} src={logo} alt="user"/>
                    </Link>
                </div>
                <img onClick={dateIconStatus} className='mobile-right-header-icons date-icon' src={calenderIcon} alt="Dates"/>
                <img onClick={searchIconStatus} className='mobile-right-header-icons' src={searchIcon} alt="Search"/>

                {/*<button onClick={dateIconStatus}>Date</button>*/}
            </div>
        </div>
    );
}

export default LiveScoresScreenMobileHeader;
