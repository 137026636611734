import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./header/Header";
import LiveScores from "./live-scores/LiveScores";
import Stats from "./stats/Stats";
import TrendMatches from "./trend-matches/TrendMatches";
import MatchDetails from "./match-details/MatchDetails";
import TeamDetails from "./team-details/TeamDetails";
import LeagueDetails from "./league-details/LeagueDetails";
import BestTeams from "./best-teams/BestTeams";
import BetMode from "./bet-mode/BetMode";
import TvGuide from "./tv/TvGuide";
import Terms from "./base-pages/Terms";
import Privacy from "./base-pages/Privacy";
import Contact from "./base-pages/Contact";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";
import JoinPremium from "./premium/JoinPremium";
import PaymentSuccess from "./payment/PaymentSuccess";
import PaymentCancel from "./payment/PaymentCancel";
import UserAccount from "./Auth/UserAccount";
import Footer from "./footer/Footer";
import CancelSubscription from "./premium/CancelSubscription";
import UpdateTeamDetail from "./update-team-detail/UpdateTeamDetail";
import FvLmtV2Container from "./fv-lmt-v2/views/FVLmtV2Container";
import Digital from "./digital/Digital";
import Landing from "./landing/Landing";
import AndroidApkView from "./android/AndroidApkView";
import AppPlatformDirect from "./app-direct/AppPlatformDirect";
import RefereeInfo from "./referee/RefereeInfo";

function Application(props) {
    return (
        <div style={{background: '#ececec'}}>
            <React.StrictMode>
                <BrowserRouter>
                    <Routes>
                        <Route path="/fv-lmt-v3/:id" element={<FvLmtV2Container/>}></Route>
                    </Routes>
                    <Header/>
                    <div className='const-width'>
                        <amp-ad width="100vw" height="320"
                                type="adsense"
                                data-ad-client="ca-pub-3133948949544793"
                                data-ad-slot="1563802729"
                                data-auto-format="rspv"
                                data-full-width="">
                            <div overflow=""></div>
                        </amp-ad>
                    </div>
                    <div>
                        <Routes>
                            <Route path="/digital" element={<Digital/>}></Route>
                            <Route path="/landing" element={<Landing/>}></Route>
                            <Route path="/android" element={<AndroidApkView/>}></Route>
                        </Routes>
                        <div className={'const-width'}>
                            <Routes>
                                <Route path="/" element={<LiveScores/>}></Route>
                                <Route path="/stats" element={<Stats/>}></Route>
                                <Route path="/trend-matches/:stats?/:type?" element={<TrendMatches/>}></Route>
                                <Route path="/match-detail/:id/:tabType?/:subTab?" element={<MatchDetails/>}></Route>
                                <Route path="/team-detail/:id" element={<TeamDetails/>}></Route>
                                <Route path="/league/:league?/:leagueId?/:seasonId?/:tabType?/:subTab?" element={<LeagueDetails/>}></Route>
                                <Route path="/best-teams/:stats?/:type?" element={<BestTeams/>}></Route>
                                <Route path="/referee/:refId?/season/:seasonId" element={<RefereeInfo/>}></Route>
                                <Route path="/scoreradar" element={<BetMode/>}></Route>
                                <Route path="/tv" element={<TvGuide/>}></Route>
                                <Route path="/terms" element={<Terms/>}></Route>
                                <Route path="/privacy" element={<Privacy/>}></Route>
                                <Route path="/contact" element={<Contact/>}></Route>
                                <Route path="/sign-in/:redirect?" element={<SignIn/>}></Route>
                                <Route path="/sign-up" element={<SignUp/>}></Route>
                                <Route path="/join-premium" element={<JoinPremium/>}></Route>
                                <Route path="/app-direct" element={<AppPlatformDirect/>}></Route>
                                <Route path="/payment/success/:session_id?" element={<PaymentSuccess/>}></Route>
                                <Route path="/payment/cancel" element={<PaymentCancel/>}></Route>
                                <Route path="/account-detail/:username" element={<UserAccount/>}></Route>
                                <Route path="/account-detail/:username/cancel-subscription" element={<CancelSubscription/>}></Route>
                                <Route path="/admin/update-team-details" element={<UpdateTeamDetail/>}></Route>
                            </Routes>
                        </div>
                        {/*<div>
                            <Routes>
                                <Route path="/scoreradar" element={<BetMode/>}></Route>
                            </Routes>
                        </div>*/}
                    </div>
                    <Footer/>
                </BrowserRouter>
            </React.StrictMode>
        </div>
    );
}

export default Application;
