import React, {useEffect, useState} from 'react';
import MatchResult from "./Statistics/MatchResult.js";
import './Statistics.css';
import StadiumInfo from "./Statistics/StadiumInfo";
import CardsInfo from "./Statistics/CardsInfo";
import PositionInfo from "./Statistics/PositionInfo";

function Statistics(props) {

    const {
        matchTeam,
        referee,
        manager,
        stadium,
        cards,
        corner,
        ballPossession,
        dangerousAttack,
        attack,
        ballSafe
    } = props;

    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        const itemsCount = document.getElementsByClassName("carousel-item").length;

        selectPage(0);
        setInterval(() => {
            setCurrentPage((currentPage + 1) % itemsCount);
            selectPage(currentPage);
        }, 8000);
    }, [])

    const selectPage = (pageNumber) => {
        setCurrentPage(pageNumber);
        const items = document.getElementsByClassName("carousel-item");
        for (let i = 0; i < items.length; i += 1) {
            const marginMultiplier = (i - pageNumber);
            const left = (marginMultiplier * 100) + '%';
            items[i].style['margin-left'] = left;
        }
        const buttons = document.getElementsByClassName("carousel-button");
        for (let i = 0; i < items.length; i += 1) {
            buttons[i].style['background'] = pageNumber === i ? 'red' : 'white';
        }
    }

    return (
        <div className="carousel">
            <div className="carousel-items-container">
                <div className="carousel-item item-1">
                    <MatchResult matchTeam={matchTeam} referee={referee} manager={manager}/>
                </div>
                <div className="carousel-item item-2">
                    <StadiumInfo stadium={stadium}/>
                </div>
                {
                    matchTeam.status._id !== 0 &&
                    <div className="carousel-item item-3"><CardsInfo cards={cards} corner={corner}/></div>
                }
                {
                    matchTeam.status._id !== 0 &&
                    <div className="carousel-item item-4">
                        <PositionInfo ballPossession={ballPossession}
                                      dangerousAttack={dangerousAttack}
                                      attack={attack}
                                      ballSafe={ballSafe} />
                    </div>

                }
            </div>


            <div className="carousel-buttons-container">
                <div className="carousel-button button-1" onClick={() => selectPage(0)}></div>
                <div className="carousel-button button-2" onClick={() => selectPage(1)}></div>
                {
                    matchTeam.status._id !== 0 &&
                    <div className="carousel-button button-3" onClick={() => selectPage(2)}></div>
                }
                {
                    matchTeam.status._id !== 0 &&
                    <div className="carousel-button button-3" onClick={() => selectPage(3)}></div>
                }
            </div>
        </div>
    );
}

export default Statistics;
