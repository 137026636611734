import React, {useEffect, useState} from 'react';
import {fetchLeagueStats} from "../../../../api";
import {Paper} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {useParams} from "react-router-dom";
import {colorTheme} from "../../../ColorCss";
import downArrow from "../../../../assets/icons/down-arrow.png";
import noTeamLogo from "../../../../assets/icons/noTeamLogo.png";

function CardOverallTable(props) {

    const {type, league, i18String} = props;
    const params = useParams();

    const [statsTableData, setStatsTableData] = useState(null);
    const [order, setOrder] = React.useState('asc');
    const [tableCellId, setTableSetId] = useState();
    useEffect(() => {
        fetchLeagueStats(params.seasonId,type).then(data => {
            setStatsTableData(data.data.data);
        })
    }, [params.seasonId])

    const headCells = [
        {
            id: 'standing',
            label: '#',
            align: 'center',
        },
        {
            id: 'teamName',
            label: `${i18String('Teams')}`,
            align: 'left',
        },
        {
            id: 'played',
            label: `${i18String('MP')}`,
            align: 'center',
        },
        {
            id: 'over05',
            label: `${i18String('Over 0.5')}`,
            align: 'center',
        },
        {
            id: 'over15',
            label: `${i18String('Over 1.5')}`,
            align: 'center',
        },
        {
            id: 'over25',
            label: `${i18String('Over 2.5')}`,
            align: 'center',
        },
        {
            id: 'over35',
            label: `${i18String('Over 3.5')}`,
            align: 'center',
        },
        {
            id: 'over45',
            label: `${i18String('Over 4.5')}`,
            align: 'center',
        },
        {
            id: 'over55',
            label: `${i18String('Over 5.5')}`,
            align: 'center',
        },
        {
            id: 'over65',
            label: `${i18String('Over 6.5')}`,
            align: 'center',
        },
        {
            id: 'avg',
            label: `${i18String('Avg')}`,
            align: 'center',
        },
    ];

    const handleRequestSort = (property) => {
        if (order === 'asc') {
            const sorted = [...statsTableData].sort((a, b) => a[property] > b[property] ? 1 : -1)
            setOrder('desc');
            setStatsTableData(sorted);
            setTableSetId(property);
        }

        if (order === 'desc') {
            const sorted = [...statsTableData].sort((a, b) => a[property] < b[property] ? 1 : -1)
            setOrder('asc');
            setStatsTableData(sorted);
            setTableSetId(property);
        }
    };

    const selectedTableHeaderCell = (cellId) => {
        if (cellId === tableCellId) {
            return {
                background: colorTheme.selectedTableStatsHeader,
                color: colorTheme.selectedTableStats,
                disabled: 'flex',
                placeItems: 'center'
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    const selectedArrowIcon = (cellId) => {
        if (cellId === tableCellId) {
            if (order === 'asc') {
                return <img className='down-arrow for-asc-arrow' src={downArrow} alt=""/>
            } else {
                return <img className='down-arrow' src={downArrow} alt=""/>
            }
        }
    }

    return (
        <div className={'league-stats-general-table-section'}>
            <div className={'league-stats-table-title-container'}>
                <span className={'league-stats-table-title'}>{league.league} - {i18String('Card Overall')}</span>
            </div>

            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}}>
                    <TableHead>
                        <TableRow className={'stats-table-header-row-container'}>
                            {
                                headCells.map(head => {
                                    return (
                                        <TableCell
                                            key={head.id}
                                            onClick={() => handleRequestSort(head.id)}
                                            align={head.align}
                                            className={'stats-table-header-cell'}
                                            style={selectedTableHeaderCell(head.id)}
                                        >
                                            <div className='stats-table-header-icon-and-title'>
                                                {selectedArrowIcon(head.id)}
                                                {head.label}
                                            </div>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={'asd'}>
                        {
                            statsTableData &&
                            statsTableData.map((row, i) => (
                                <TableRow
                                    key={row.teamId}
                                    className={'league-stats-table-row'}
                                >
                                    <TableCell className={'league-stats-table-td'} align="center">
                                        <span className={'standings-text'}>{i+1}</span>
                                    </TableCell>
                                    <TableCell scope="row" className={'league-stats-table-td'}
                                    >
                                        <div className={'league-stats-team-and-logo-container'}>
                                            <img className="league-stats-teams-logo"
                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${row.teamId}.png`}
                                                 onError={(e) => onImageError(e)}
                                                 alt={row.teamName}/>
                                            <a target={"_blank"} href={`/team-detail/${row.teamId}`}
                                               className={'league-stats-team-name'}>{row.teamName}</a>
                                        </div>
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.played ? <span>{row.played}</span> : <span>0</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.over05 ? <span>{row.over05}%</span> : <span>0%</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.over15 ? <span>{row.over15}%</span> : <span>0%</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.over25 ? <span>{row.over25}%</span> : <span>0%</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.over35 ? <span>{row.over35}%</span> : <span>0%</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.over45 ? <span>{row.over45}%</span> : <span>0%</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.over55 ? <span>{row.over55}%</span> : <span>0%</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td'}
                                               align="center">
                                        {
                                            row.over65 ? <span>{row.over65}%</span> : <span>0%</span>
                                        }
                                    </TableCell>
                                    <TableCell className={'league-stats-table-td-Avg'} align="center">
                                        {
                                            row.avg ? <span>{(Math.round(row.avg * 100) / 100).toFixed(2)}</span> : <span>0</span>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default CardOverallTable;

