import React from 'react';
import './Throwing.css'

function Throwing(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props

    return (
        <div className="position-container">
            <div className="throwin-container blink">
                <div className="throwin">
                    <div className="throwin-match-time">
                        {selectedEvent.time}'
                    </div>
                    <div className="font-size">
                        <div>
                            <span style={{fontWeight: 500}}>{i18n?.throwin}</span> <br/> <span
                            className="throwin-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Throwing;
