import React from 'react';
import './InjuryTime.css';

function InjuryTime(props) {

    const {
        i18n,
        matchTeam
    } = props;

    return (
        <div className="position-container">
            <div className="injury-time-container">
                <div className="injury-time">
                    <span>{i18n?.injuryTime}</span>
                    <span>{matchTeam?.timeinfo?.injurytime}'</span>
                </div>
            </div>
        </div>
    );
}

export default InjuryTime;
