import React, {useContext, useEffect, useState} from 'react';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import './LeagueDetails.css'
import LeagueStats from "./league-stats/LeagueStats";
import LeagueStandings from "./league-standings/LeagueStandings";
import {
    fetchAllLeagues, fetchAreas,
    fetchDefaultLeague,
    fetchLeagueDetail,
    fetchLeagueStandings,
    fetchPopularLeagues
} from "../../api";
import {useNavigate, useParams} from "react-router-dom";
import {FormControl, InputLabel, MenuItem, NativeSelect, Select} from "@mui/material";
import Results from "./results/Results";
import Fixtures from "./fixtures/Fixtures";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {leagueDetailsMeta} from "../../SEO/SeoMetaTags";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";
import LinearComponent from "../linearComponent/LinearComponent";
import LeaguePlayerStats from "./player-stats/LeaguePlayerStats";

function LeagueDetails(props) {
    const {lang, i18String} = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState(params.tabType ? params.tabType : "stats")
    const [leagueStandingsData, setLeagueStandingsData] = useState(null)
    const [defaultData, setDefaultData] = useState(null)
    const [allLeagues, setAllLeagues] = useState(null)
    const [areasData, setAreasData] = useState(null)
    const [popularLeagues, setPopularLeagues] = useState(null);
    const [selectedLeagueID, setSelectedLeagueID] = useState('');
    const [league, setLeague] = useState(0);

    useEffect(() => {
        if (!params.seasonId || !params.leagueId) {
            fetchDefaultLeague().then(data => {
                setDefaultData(data.data.data);
                navigate(`/league/${defaultData.area.toLowerCase()}-${defaultData.league.toLowerCase().replace(/\s+/g, '-')}/${defaultData.leagueId}/${defaultData.seasonId}/${selectedType}`);
            })
        } else {
            const standings = async () => {
                await fetchLeagueStandings(params.seasonId).then(standingsData => {
                    if (standingsData) {
                        const leagueStandings = standingsData.data.data.groups[0].rows;
                        setLeagueStandingsData(leagueStandings);
                    }
                });
            }
            standings().catch(e => console.log(e))

            const leagueDetail = async () => {
                await fetchLeagueDetail(params.leagueId).then(data => {
                    setLeague(data.data.data);
                });
            }
            leagueDetail().catch(e => console.log(e))
        }

        fetchPopularLeagues().then(data => {
            setPopularLeagues(data.data.data);
        })

        fetchAllLeagues().then(data => {
            setAllLeagues(data.data.data);
        })

        fetchAreas().then(data => {
            setAreasData(data.data.data);
        })

    }, [defaultData, params.seasonId, params.leagueId]);

    /*useEffect(() => {
        if (selectedType) {
            // URL'den gelen "type" değerine göre aktif tabı belirle
            const foundTab = leagueTabs.find(tab => tab.type === selectedType);
            if (foundTab) {
                setActiveTab(foundTab.toggle);
            }
        }
    }, [selectedType]);*/

    const handleTabChange = (tab) => {
        // Tab değiştirildiğinde ilgili URL'e yönlendir
        const newPath = `/league/${league.area.toLowerCase()}-${league.league.toLowerCase().replace(/\s+/g, '-')}/${params?.leagueId}/${params?.seasonId}/${tab.type}`;
        navigate(newPath);
        setSelectedType(tab.type);
    };

    const leagueTabs = [
        {
            tabsName: `${i18String('Stats')}`,
            type: 'stats',
            toggle: 0,
        },
        {
            tabsName: `${i18String('Standings')}`,
            type: 'standings',
            toggle: 1,
        },
        {
            tabsName: `${i18String('Results')}`,
            type: 'results',
            toggle: 2,
        },
        {
            tabsName: `${i18String('Fixtures')}`,
            type: 'fixtures',
            toggle: 3,
        },
        {
            tabsName: `${i18String('Player Stats')}`,
            type: 'player-stats',
            toggle: 4,
        }
    ];
    const subTabIndex = leagueTabs.findIndex((st) => st.type === selectedType)

    if (!league && defaultData) {
        return (
            <div className="loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    const handleChange = () => {
        let value = document.getElementById("leagues").value;
        console.log('value', value);
        const seasonId = JSON.parse(value).seasonId;
        const leagueId = JSON.parse(value).leagueId;
        const area = JSON.parse(value).area;
        const league = JSON.parse(value).league;
        setSelectedLeagueID(value);
        navigate(`/league/${area.toLowerCase().replace(/\s+/g, '-')}-${league.toLowerCase().replace(/\s+/g, '-')}/${leagueId}/${seasonId}/${params.tabType}`);
    };
    const handleChangeForPopularLeague = () => {
        let value = document.getElementById("popularLeagues").value;
        const seasonId = JSON.parse(value).seasonId;
        const leagueId = JSON.parse(value).leagueId;
        const area = JSON.parse(value).area;
        const league = JSON.parse(value).league;
        setSelectedLeagueID(value);
        navigate(`/league/${area.toLowerCase().replace(/\s+/g, '-')}-${league.toLowerCase().replace(/\s+/g, '-')}/${leagueId}/${seasonId}/${params.tabType}`);
    };
    const handleChangeForAreas = () => {
        let value = document.getElementById("areas").value;
        const seasonId = JSON.parse(value).seasonId;
        const leagueId = JSON.parse(value).leagueId;
        const area = JSON.parse(value).area;
        const league = JSON.parse(value).league;
        setSelectedLeagueID(value);
        navigate(`/league/${area.toLowerCase().replace(/\s+/g, '-')}-${league.toLowerCase().replace(/\s+/g, '-')}/${leagueId}/${seasonId}/${params.tabType}`);
    };

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <DocumentMeta {...leagueDetailsMeta(league)[lang]}>
            <div style={{width: '100%'}}>
                <div className={'league-details-flag-and-name-section const-width-content'}>
                    <div className='league-linear-container'>
                        <LinearComponent />
                    </div>

                    <div className='leagues-select-section'>
                        <div className='league-details-all-leagues-container'>
                            <label className='selects-label' htmlFor="">{i18String('Popular Leagues')}</label>
                            <FormControl>
                                <NativeSelect
                                    sx={{
                                        width: '180px',
                                        background: '#f3f3f3',
                                        marginTop: '5px'
                                    }}
                                    id="popularLeagues"
                                    key={league.leagueId}
                                    value={selectedLeagueID}
                                    onChange={(e) => handleChangeForPopularLeague(e)}
                                >
                                    {
                                        popularLeagues &&
                                        popularLeagues.map(league => {
                                            return (
                                                <option key={league.leagueId}
                                                        value={JSON.stringify(league)}>{league.league}</option>
                                            )
                                        })
                                    }
                                </NativeSelect>
                            </FormControl>
                        </div>

                        <div className='league-details-all-leagues-container'>
                            <label className='selects-label' htmlFor="">{i18String('Other Leagues')}</label>
                            <FormControl>
                                <NativeSelect
                                    sx={{
                                        width: '180px',
                                        background: '#f3f3f3'
                                    }}
                                    id="leagues"
                                    key={league.leagueId}
                                    value={selectedLeagueID}
                                    onChange={(e) => handleChange(e)}
                                >
                                    {
                                        allLeagues &&
                                        allLeagues.map(league => {
                                            return (
                                                <option key={league.leagueId}
                                                        value={JSON.stringify(league)}>{league.league}</option>
                                            )
                                        })
                                    }
                                </NativeSelect>
                            </FormControl>
                        </div>

                        <div className='league-details-all-leagues-container'>
                            <label className='selects-label' htmlFor="">{i18String('Areas')}</label>
                            <FormControl>
                                {/*<InputLabel id="demo-simple-select-label">All Leagues</InputLabel>*/}
                                <NativeSelect
                                    sx={{
                                        width: '180px',
                                        background: '#f3f3f3'
                                    }}
                                    id="areas"
                                    key={league.leagueId}
                                    value={selectedLeagueID}
                                    onChange={(e) => handleChangeForAreas(e)}
                                >
                                    {
                                        areasData &&
                                        areasData.map(area => {
                                            return (
                                                <optgroup key={area.id} label={area.name}>
                                                    {
                                                        area.leagues.map(league => {
                                                            return (
                                                                <option key={league.leagueId}
                                                                        value={JSON.stringify(league)}>{league.league}</option>
                                                            )
                                                        })
                                                    }
                                                </optgroup>
                                            )
                                        })
                                    }
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className={'league-details-general-section'}>
                    <div className={'league-details-flag-and-name-section const-width-content'}>
                        <div className={'flag-and-league-name-container'}>
                            <img className="league-details-country-flag"
                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                 onError={(e) => onImageError(e)}
                                 alt={league.league}/>
                            <span className={'league-details-league-name'}>{league.area} {league.league}</span>
                        </div>
                    </div>

                    <div className='league-details-top-tabs-section'>
                        <Tabs
                            variant={'scrollable'}
                            scrollButtons='auto'
                            value={subTabIndex}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#000",
                                }
                            }}
                        >
                            {
                                leagueTabs.map((tabTitle, index) => {
                                    return (<Tab
                                        key={tabTitle.type}
                                        className={'league-details-tab-item'}
                                        onClick={() => handleTabChange(tabTitle)}
                                        value={index}
                                        label={tabTitle.tabsName}
                                        sx={{
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            '&.Mui-selected': {
                                                color: 'var(--H3Dark0)', // Seçili tabın rengi kırmızı
                                            },
                                            color: 'var(--H3TeamFormD)',
                                            textTransform: 'none'
                                        }}
                                    />)
                                })
                            }
                        </Tabs>
                    </div>

                    {
                        params.tabType === 'stats' && <LeagueStats league={league}/>
                    }

                    {
                        params.tabType === 'standings' && <LeagueStandings leagueStandingsData={leagueStandingsData}/>
                    }

                    {
                        params.tabType === 'results' && <Results/>
                    }

                    {
                        params.tabType === 'fixtures' && <Fixtures/>
                    }

                    {
                        params.tabType === 'player-stats' && <LeaguePlayerStats league={league}/>
                    }
                </div>
            </div>
        </DocumentMeta>
    );
}

export default LeagueDetails;
