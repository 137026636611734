import React from 'react';
import './Freekick.css'

function Freekick(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className='position-container'>
            <div className="freekick-container blink">
                <div className="freekick">
                    <div className="freekick-match-time">
                        {selectedEvent.time}'
                    </div>
                    <div className="font-size">
                        <div>
                            <span style={{fontWeight: 500}}>{i18n?.freekick}</span> <br/> <span
                            className="freekick-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Freekick;
