import React, {useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {AppContext} from "../../context/AppContext";

function UserAccount(props) {
    const {lang} = useContext(AppContext);

    const params = useParams();
    const userDetail = localStorage.getItem('user');
    const user = JSON.parse(userDetail);

    const startTime = (value) => {
        return dayjs(value).format("YYYY/MM/DD ddd HH:hh")
    }

    return (
        <div className='const-width'>
            <div className='user-account-detail-section'>
                <h3>@{params.username}</h3>

                <div>
                    {
                        user &&
                        <div>
                            <div className='user-label-container'>
                                {
                                    lang === 'tr' ?
                                        <span className='user-label'>Ad Soyad: </span>
                                        :
                                        <span className='user-label'>Name: </span>
                                }
                                <span className='user-label-text'>{user.name}</span>
                            </div>
                            <div className='user-label-container'>
                                <span className='user-label'>E-Mail: </span>
                                <span className='user-label-text'>{user.email}</span>
                            </div>
                            <div className='user-label-container'>
                                {
                                    lang === 'tr' ?
                                        <span className='user-label'>Kayıt Tarihi: </span>
                                        :
                                        <span className='user-label'>Date Of Registration: </span>
                                }
                                <span className='user-label-text'>{startTime(user.dateCreated)}</span>
                            </div>
                            <div className='user-label-container'>
                                {
                                    lang === 'tr' ?
                                        <span className='user-label'>Hesap Türü: </span>
                                        :
                                        <span className='user-label'>Account Type: </span>
                                }
                                <span className='user-label-text'>{user.premium && user.premium.status !== 'canceled' ? 'Premium' : `Basic `}</span>
                            </div>

                            {/*{
                                user.premium === null &&
                                <div className='premium-button-container'>
                                    <Link className='join-premium-button' to={'/join-premium'}>join Premium</Link>
                                </div>
                            }*/}

                            {
                                user.premium && user.premium.status === 'active' &&
                                <Link className='unsubscription-text' to={`/account-detail/${user.username}/cancel-subscription`}>
                                    {
                                        lang === 'tr' ?
                                            <span>Premium aboneliğimi iptal etmek istiyorum.</span>
                                            :
                                            <span>I want to unsubscribe from Premium.</span>
                                    }
                                </Link>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default UserAccount;
