import React from 'react';
import offsideFlag from '../../../../assets/lmt/offsideFlag.svg';
import './Offside.css';

function Offside(props) {

    const {
        i18n,
        selectedEvent,
        matchTeam
    } = props;

    return (
        <div className='position-container'>
            <div className="offside-container blink">
                <div className="offside">
                    <div>
                        <img className="match-situation-icon" src={offsideFlag} alt=""/>
                    </div>
                    <div className="offside-info">
                        <div className="offside-match-time">
                            {selectedEvent.time}'
                        </div>
                        <div className="font-size">
                            <span style={{fontWeight: 500}}>{i18n?.offside}</span> <br/> <span
                            className="offside-team-name">{matchTeam.teams[selectedEvent.team].name.toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Offside;
